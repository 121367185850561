/**
 * @file Verify with a code
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { PureInput } from '../input'
import Button from '../button'
import {
  getNextResendVerificationDate,
  sendVerificationCode,
  verifyAccount,
} from '../../actions/auth'

dayjs.extend(duration)

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;

  > * {
    margin-bottom: 10px;
  }
`

const AuthError = styled.p`
  color: ${({ theme }) => theme.Accent};
`

const Resend = styled.button`
  color: ${({ theme }) => theme.Accent};
  background-color: transparent;
  outline: none;
  border: 0;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const Verify = () => {
  const dispatch = useDispatch()
  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const user = useSelector(state => state.auth.user)
  const authError = useSelector(state => state.auth.authError)
  const nextResendVerificationDate = useSelector(
    state => state.auth.nextResendVerificationDate
  )
  const [timeLeft, setTimeLeft] = useState(null)

  useEffect(() => {
    dispatch(getNextResendVerificationDate())
  }, [])

  useEffect(() => {
    const updateTimeLeft = () => {
      if (
        nextResendVerificationDate &&
        dayjs(nextResendVerificationDate).isAfter(dayjs())
      ) {
        // TODO: dayjs is releasing a duration.format soon. Change to that once it's out
        const d = dayjs.duration(
          dayjs(nextResendVerificationDate).diff(dayjs())
        )
        setTimeLeft(dayjs(d.asMilliseconds()).format('m:ss'))
      } else {
        setTimeLeft(null)
      }
    }

    const countdownTimer = setInterval(updateTimeLeft, 1000)
    updateTimeLeft()

    return () => {
      clearInterval(countdownTimer)
    }
  }, [nextResendVerificationDate])

  const onSubmit = values => {
    dispatch(
      verifyAccount({
        verificationCode: values.verificationCode,
      })
    )
  }

  const handleResendCode = () => {
    dispatch(sendVerificationCode())
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: 80 }}>
        <h2>Hi {user?.name}!</h2>
        <h2>Just one last step!</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>
        What’s the 6 digit pin sent to {user?.phoneNumber}
      </h4>
      <PureInput
        placeholder="Enter Pin"
        name="verificationCode"
        type="Text"
        autoComplete="one-time-code"
        pattern="[0-9]*"
        ref={register({ required: true })}
        style={{ paddingRight: 15, textAlign: 'center' }}
      />
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
      {authError && <AuthError>{authError}</AuthError>}
      <h5 style={{ fontSize: 14 }}>
        Didnt't receive a code?{' '}
        <Resend onClick={handleResendCode} disabled={timeLeft}>
          Resend Code{timeLeft && ` (${timeLeft})`}
        </Resend>
      </h5>
    </Form>
  )
}

export default Verify
