/**
 * @file Calls Parent Class
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AppLayout from '../appLayout'

const Container = styled.div`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100%;
  }
`

const Calls = ({ children }) => {
  return (
    <AppLayout>
      <Container>{children}</Container>
    </AppLayout>
  )
}

Calls.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Calls
