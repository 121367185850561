/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  confirmEventMatches,
  getEventDetails,
  getEventMatches,
} from '../../actions/events'
import Button from '../button'

const Heading = styled.div`
  text-align: center;
  padding-bottom: 20px;
`

const Section = styled.section`
  > div {
    position: relative;
  }

  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.Secondary};
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;

  h4 {
    width: 100%;
    padding: 5px;
    position: absolute;
    bottom: 0px;
  }
`

const Image = styled.div`
  border: 0px solid ${({ theme }) => theme.Accent};
  border-width: ${props => (props.selected ? '5px' : 0)};
  width: 155px;
  height: 155px;
  border-radius: 10px;
  background-image: ${props =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%), url(${props.src})`};
  background-size: cover;

  @media only screen and (max-width: 360px) {
    /* For screens smaller than 360px (eg. iphone SE) */
    width: 115px;
    height: 115px;
  }
`

const MatchesReview = ({ id }) => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState([])
  const [newMatches, setNewMatches] = useState([])
  const eventData = useSelector(state => state.events.eventDetails[id])
  const matches = useSelector(state => state.events.matches[id]?.data)
  const matchesIsFixed = useSelector(state => state.events.matches[id]?.isFixed)
  const matchesIsLoading = useSelector(
    state => state.events.matches[id]?.matchesIsLoading
  )

  useEffect(() => {
    // retrieve the matches
    dispatch(getEventMatches(id))
    dispatch(getEventDetails(id))
  }, [dispatch, id])

  useEffect(() => {
    if (matches) setNewMatches(matches)
  }, [matches])

  useEffect(() => {
    if (selected.length === 2) {
      const [x1, y1] = selected[0].split(',').map(e => Number(e))
      const [x2, y2] = selected[1].split(',').map(e => Number(e))

      const tempMatches = [...newMatches]
      const tempElem1 = tempMatches[x1][y1]
      tempMatches[x1][y1] = tempMatches[x2][y2]
      tempMatches[x2][y2] = tempElem1

      setNewMatches(tempMatches)
      setSelected([])
    }
  }, [selected, newMatches])

  const handleConfirmMatches = () => {
    dispatch(
      confirmEventMatches({
        eventID: id,
        matches: newMatches.map(i => i.map(j => j.id)),
      })
    )
  }

  const renderMatches = () => {
    const handleImageClick = pos => {
      if (matchesIsFixed) return
      const newSelected = [...selected]
      const index = newSelected.indexOf(pos)

      if (index > -1) {
        newSelected.splice(index, 1)
      } else {
        newSelected.push(pos)
      }

      setSelected(newSelected)
    }

    return newMatches.map((pair, index) => (
      <Section key={`match-${index + 1}`}>
        {pair.map((p, j) => (
          <div key={p.id}>
            <Image
              style={{ cursor: matchesIsFixed ? 'default' : 'pointer' }}
              selected={selected.includes([index, j].join(','))}
              src={p.picture}
              onClick={() => handleImageClick([index, j].join(','))}
            />
            <h4>{p.name}</h4>
          </div>
        ))}
      </Section>
    ))
  }

  if (!matches) return null
  if (matchesIsLoading) return <h3>Loading</h3>

  return (
    <div>
      <Heading>
        <h4 style={{ padding: 10 }}>Match Review</h4>
        <h2 style={{ paddingBottom: 5 }}>{eventData.title}</h2>
        <h5>Tap 2 to exchange matches</h5>
      </Heading>
      {renderMatches()}
      {!matchesIsFixed && (
        <Button floating onClick={handleConfirmMatches}>
          Confirm Matches
        </Button>
      )}
    </div>
  )
}

MatchesReview.propTypes = {
  id: PropTypes.string.isRequired,
}

export default MatchesReview
