/**
 * @file Create Event page 1
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { PureInput } from '../../input'
import Checkbox from '../../Checkbox'

const Page0 = ({ register, control, watch }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  const currentStartDate = watch('startDate')

  return (
    <>
      <h3>Event Title</h3>
      <PureInput
        name="title"
        placeholder="Event Title"
        defaultValue={currentFormData.title || ''}
        ref={register({ required: true })}
      />
      <h3>Start Date and Time</h3>
      <Controller
        control={control}
        name="startDate"
        rules={{
          required: true,
          validate: value => dayjs(value).isAfter(dayjs()),
        }}
        defaultValue={() =>
          currentFormData.startDate
            ? dayjs(currentFormData.startDate).toDate()
            : ''
        }
        render={props => (
          <ReactDatePicker
            {...props}
            placeholderText="mm/dd/yyyy"
            selected={props.value} // eslint-disable-line react/prop-types
            customInput={<PureInput />}
            showTimeSelect
            dateFormat="Pp"
          />
        )}
      />
      <h3>End Date and Time</h3>
      <Controller
        control={control}
        name="endDate"
        rules={{
          required: true,
          validate: value => dayjs(value).isAfter(dayjs(currentStartDate)),
        }}
        defaultValue={() =>
          currentFormData.endDate ? dayjs(currentFormData.endDate).toDate() : ''
        }
        render={props => (
          <ReactDatePicker
            {...props}
            placeholderText="mm/dd/yyyy"
            selected={props.value} // eslint-disable-line react/prop-types
            customInput={<PureInput />}
            showTimeSelect
            dateFormat="Pp"
          />
        )}
      />
      <Checkbox ref={register} name="private" checked disabled>
        Private Event (Only private events are supported currently)
      </Checkbox>
    </>
  )
}

Page0.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  watch: PropTypes.func.isRequired,
}

export default Page0
