/**
 * @file Sign Up Form
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, navigate } from 'gatsby'
import { PureInput } from '../input'
import Button from '../button'
import {
  registerAccount,
  setAuthError,
  setAuthPhoneNumber,
  setCurrentUser,
} from '../../actions/auth'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.Accent};
  cursor: pointer;
  text-decoration: none;
`

const ClickHere = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.Accent};
`

const AuthError = styled.p`
  color: ${({ theme }) => theme.Accent};
`

const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.Accent};
  padding-bottom: 10px;
`

const Register = () => {
  const dispatch = useDispatch()
  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onChange',
  })
  const user = useSelector(state => state.auth.user)
  const isLoading = useSelector(state => state.auth.isLoading)
  const authError = useSelector(state => state.auth.authError)
  const authPhoneNumber = useSelector(state => state.auth.authPhoneNumber)

  const onSubmit = values => {
    dispatch(
      registerAccount({
        name: values.name,
        password: values.password,
      })
    )
  }

  const handleResetUser = () => {
    navigate(`${window.location.origin}${window.location.pathname}`, {
      replace: true,
    })
    setTimeout(() => {
      dispatch(setAuthPhoneNumber(null))
      dispatch(setCurrentUser(null))
      dispatch(setAuthError(false))
    }, 100)
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>
          Hi <ClickHere onClick={handleResetUser}>{authPhoneNumber}</ClickHere>
        </h2>
        <h2>Let's get you started!</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>Let's make you an account</h4>
      <PureInput
        placeholder="Name"
        name="name"
        type="text"
        autoComplete="name"
        defaultValue={user?.name || ''}
        ref={register({
          required: true,
          validate: value => value.length || 'This is required.',
        })}
      />
      <PureInput
        placeholder="Password"
        name="password"
        type="password"
        autoComplete="new-password"
        ref={register({
          required: true,
          validate: value =>
            value.match(/^(?=.*\d)(?=.*[A-Za-z]).{8,}$/) ||
            'Password needs to have a minimum of 8 letters and a number',
        })}
        style={{
          border: errors.password?.message && '2px solid red',
        }}
      />
      {errors.password?.message && (
        <ErrorMsg>{errors.password?.message}</ErrorMsg>
      )}
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
      {authError && <AuthError>{authError}</AuthError>}
      <h5 style={{ fontSize: 14 }}>
        By continuing, you acknowledge that you have read and agreed to our{' '}
        <StyledLink to="/terms">terms</StyledLink> and{' '}
        <StyledLink to="/privacy">privacy policy</StyledLink>
      </h5>
      <h5 style={{ paddingTop: 10, fontSize: 14 }}>
        <ClickHere onClick={handleResetUser}>Incorrect Number?</ClickHere>
      </h5>
    </Form>
  )
}

export default Register
