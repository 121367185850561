/**
 * @file User Profile Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import AppLayout from '../appLayout'
import placeholderImage from '../../images/profilePlaceholder.png'
import ProfileCard from '../profile/profileCard'
import { getUserInfo } from '../../actions/user'
import { createChatRoomWith } from '../../actions/chat'
import SEO from '../seo'
import ButtonV2 from '../ButtonV2'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  height: 100%;
  padding: 50px 0;
  width: 100%;
  max-width: 400px;
  text-align: center;
`

const ProfilePicture = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
`

const ProfileBar = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;

  > div {
    padding-left: 20px;

    h2 {
      padding-bottom: 15px;
    }
  }
`

const userSelector = createSelector(
  (state, id) => state.auth.user?.id === id,
  (state, id) => state.user.info[id],
  state => state.auth.user,
  (isCurrentUser, otherUserInfo, currentUserInfo) =>
    isCurrentUser ? currentUserInfo : otherUserInfo
)

const User = ({ id }) => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(state => state.auth.accessToken)
  const isCurrentUser = useSelector(state => state.auth.user?.id === id)
  const user = useSelector(state => userSelector(state, id))

  useEffect(() => {
    if (!isCurrentUser) dispatch(getUserInfo({ id }))
  }, [dispatch, user?.id, id])

  useEffect(() => {
    if (user?.notExist) {
      navigate('/app/404')
    }
  }, [user])

  if (!user)
    return (
      <AppLayout>
        <></>
      </AppLayout>
    )

  const handleSendMessageClick = () => dispatch(createChatRoomWith(id))

  const handleEditProfileClick = () => navigate('/app/profile/edit')

  const renderProfileButton = () => {
    if (!isLoggedIn) return null

    if (isCurrentUser) {
      return (
        <ButtonV2 size="small" onClick={handleEditProfileClick}>
          <>
            <span className="icon-edit" style={{ paddingRight: 7 }} />
            Edit Profile
          </>
        </ButtonV2>
      )
    }

    return (
      <ButtonV2 size="small" onClick={handleSendMessageClick}>
        <>
          <span className="icon-send" style={{ paddingRight: 7 }} />
          Message
        </>
      </ButtonV2>
    )
  }

  return (
    <AppLayout>
      <SEO title={user.name} image={user.picture} />
      <CenterContainer>
        <Container>
          <ProfileBar>
            <ProfilePicture src={user.picture || placeholderImage} />
            <div>
              <h2>{user.name}</h2>
              {renderProfileButton()}
            </div>
          </ProfileBar>
          <ProfileCard title="Talk to me about" content={user.profession} />
          <ProfileCard title="Find me in" content={user.location} />
          <ProfileCard title="Did you know" content={user.funFact} />
          <ProfileCard
            title="Birthday"
            content={user.birthDate && dayjs(user.birthDate).format('MMMM D')}
          />
        </Container>
      </CenterContainer>
    </AppLayout>
  )
}

User.propTypes = { id: PropTypes.string }

User.defaultProps = { id: '' }

export default User
