/**
 * @file LinkedIn Callback screen
 * @author Alwyn Tan
 */

// TODO: review the errors that could happen here
import React, { useEffect } from 'react'
import { convertQueryParamsToObject } from '../../utils/url'

const LinkedInCallback = () => {
  useEffect(() => {
    if (window.location.search) {
      const params = convertQueryParamsToObject(window.location.search)
      if (window.opener) {
        window.opener.postMessage(params)
      }
    }
  }, [])

  return <></>
}

export default LinkedInCallback
