/**
 * @file Call Detail Page that tells the user all about the hotline
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import { getHotline, registerCall, unregisterCall } from '../../actions/calls'
import PlainCard from '../Cards/PlainCard'
import ButtonV2 from '../ButtonV2'

dayjs.extend(weekday)

const Section = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid
    ${({ theme }) => (theme.isDarkMode ? '#ffffff' : '#000000')}26;

  h3 {
    padding-bottom: 5px;
  }

  p {
    white-space: pre-wrap;
  }
`

const PodRequirement = styled.div`
  display: flex;
  padding-top: 5px;
  align-items: center;
`

const Tag = styled.h5`
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.Tertiary};
  margin-left: 7px;
`

const TimeSlotSection = styled.div`
  :not(:first-child) {
    padding-top: 10px;
  }
`

const TimeSlots = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 92px);
  grid-gap: 7px;
`

const MarginBottom = styled.div`
  height: 60px;
`

const weekdays = [
  'Sundays',
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays',
  'Saturdays',
]

const callsSelector = createSelector(
  (state, id) =>
    Object.values(state.calls.callDetails).filter(i => i.hotline === id),
  calls =>
    calls.sort((a, b) =>
      dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? -1 : 1
    )
)

const CallDetails = ({ id }) => {
  const dispatch = useDispatch()
  const hotline = useSelector(state => state.calls.hotlineDetails[id])
  const calls = useSelector(state => callsSelector(state, id))
  const currentUserID = useSelector(state => state.auth.user?.id)

  useEffect(() => {
    dispatch(getHotline(id))
  }, [])

  const renderAvailableTimes = () => {
    const timeSlots = weekdays.map(() => [])
    let registeredCount = 0

    for (const call of calls) {
      const daySlot = dayjs(call.startDate).weekday()
      const userRegistered = call.registered.includes(currentUserID)
      if (userRegistered) registeredCount += 1

      const handleTimeSlotClick = () => {
        dispatch(
          userRegistered ? unregisterCall(call.id) : registerCall(call.id)
        )
      }

      timeSlots[daySlot].push(
        <div style={{ width: 92 }} key={call.id}>
          <ButtonV2
            size="small"
            type={userRegistered ? 'primary' : 'secondary'}
            onClick={handleTimeSlotClick}
          >
            {dayjs(call.startDate).format('hh:mm a')}
          </ButtonV2>
        </div>
      )
    }

    return (
      <div>
        {calls.length > 0 ? (
          <>
            <p style={{ opacity: 0.5 }}>{registeredCount} selected</p>
            {timeSlots.map(
              (arr, index) =>
                arr.length > 0 && (
                  <TimeSlotSection key={weekdays[index]}>
                    <h4 style={{ paddingBottom: 10 }}>{weekdays[index]}</h4>
                    <TimeSlots>{arr}</TimeSlots>
                  </TimeSlotSection>
                )
            )}
          </>
        ) : (
          <p>
            <i>No available time slots</i>
          </p>
        )}
      </div>
    )
  }

  const renderPodRequirements = () =>
    hotline.superpods.map(s => <Tag key={s.id}>{s.name}</Tag>)

  if (!hotline) return null

  return (
    <div>
      <PlainCard style={{ maxWidth: 400 }}>
        <div>
          <h2>{hotline.title}</h2>
          {hotline.superpods && (
            <PodRequirement>
              <h5 style={{ fontWeight: 'normal' }}>
                <i>Pod Requirement:</i>
              </h5>
              {renderPodRequirements()}
            </PodRequirement>
          )}
          <p style={{ marginTop: 15 }}>{hotline.description}</p>
          <Section>
            <h3>
              Available Times{' '}
              <span style={{ opacity: 0.5, fontSize: '.8em' }}>
                (tap to select)
              </span>
            </h3>
            {renderAvailableTimes()}
          </Section>
          <Section>
            <h3>How it Works</h3>
            <p>
              1. Register for weekly time slots. <br />
              2. Sit back and let us call you. <br />
              3. Talk for {hotline.duration} minutes and connect!
              <br />
              <br />
              Busy? Let it go to voicemail, we’ll call you again next week.
            </p>
          </Section>
        </div>
      </PlainCard>
      <MarginBottom />
    </div>
  )
}

CallDetails.propTypes = {
  id: PropTypes.string,
}

CallDetails.defaultProps = {
  id: '',
}

export default CallDetails
