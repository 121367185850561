/**
 * @file View for all the chat messages
 * @author Alwyn Tan
 */

import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import ChatMessageInput from './chatMessageInput'
import { sendChatMessage, loadChatMessages, seenChat } from '../../actions/chat'
import placeholderImage from '../../images/profilePlaceholder.png'

const NoGlobalScroll = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  @media only screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.Primary};
  }
`

const ChatMessages = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: scroll;
  padding-top: 50px;
`

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-bottom: 20px;
`

const MessageContainer = styled.div`
  display: flex;
  align-self: ${props => (props.isOther ? 'flex-start' : 'flex-end')};
  max-width: 60%;
  align-items: flex-end;
`

const Message = styled.div`
  font-weight: 400;
  border-radius: 22px;
  padding: 10px 15px;
  max-width: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.Text};
  background-color: ${props =>
    props.isOther ? 'transparent' : props.theme.Tertiary};
  border-width: ${props => (props.isOther ? '1px' : '0')};
  overflow-wrap: break-word;
`

const LoadMoreText = styled.p`
  text-align: center;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (hover: hover) {
    :hover {
      opacity: 0.5;
    }
  }
`

const ChatHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  padding: 0 20px 0 14px;
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.Primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.Primary};
    border-bottom: 1px solid ${({ theme }) => theme.Secondary};
  }
`

const ChatHeaderInfo = styled.div`
  display: flex;
  align-items: center;

  > img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 10px;
    object-fit: cover;
  }
`

const BackButton = styled.span`
  font-size: 22px;
  padding-right: 5px;
`

const MiniProfileImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 12.5px;
  object-fit: cover;
`

const selectMessages = createSelector(
  (state, { pending }) =>
    pending ? state.chat.pendingMessages : state.chat.messages,
  (_, { roomID }) => roomID,
  (messages, roomID) => messages[roomID] || []
)

const selectRoomDetails = createSelector(
  state => state.chat.roomDetails,
  (_, id) => id,
  (roomDetails, id) => roomDetails[id]
)

const ChatMessageView = ({ roomID }) => {
  const dispatch = useDispatch()
  const roomDetails = useSelector(state => selectRoomDetails(state, roomID))
  const messages = useSelector(state => selectMessages(state, { roomID }))
  const pendingMessages = useSelector(state =>
    selectMessages(state, { roomID, pending: true })
  )
  const currentUser = useSelector(state => state.auth.user)
  const isScrollingRef = useRef(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const chatRef = useRef(null)

  const scrollMessages = () => {
    if (chatRef.current)
      chatRef.current.scrollTop = chatRef.current.scrollHeight
  }

  useEffect(() => {
    scrollMessages()
  }, [])

  useEffect(() => {
    dispatch(seenChat({ roomID }))
  }, [dispatch, roomID])

  // todo: mini optimization, we don't want this to fire when is scrolling is changing actually.....
  useEffect(() => {
    if (!isScrollingRef.current) {
      scrollMessages()
    }
  }, [messages])

  const handleScroll = () => {
    if (
      chatRef.current.scrollTop + chatRef.current.clientHeight ===
      chatRef.current.scrollHeight
    ) {
      if (isScrollingRef.current) isScrollingRef.current = false
    } else if (!isScrollingRef.current) isScrollingRef.current = true
  }

  // User has read the message
  const handleContainerFocus = () => {
    dispatch(seenChat({ roomID }))
  }

  const handleLoadMore = () =>
    dispatch(loadChatMessages({ roomID, oldestLoadedMessage: messages[0] }))

  const renderChatMessages = () => {
    const messageArr = messages.map((message, index) => {
      const isOther = message.from.id !== currentUser.id
      const prevMessage = index !== 0 && messages[index - 1]
      const nextMessage = messages.length > index + 1 && messages[index + 1]

      return (
        <MessageContainer
          key={message.id}
          isOther={isOther}
          style={{
            marginTop: prevMessage?.from?.id === message.from.id ? 2.5 : 5,
            marginBottom: nextMessage?.from?.id === message.from.id ? 2.5 : 5,
          }}
        >
          {isOther && (
            <MiniProfileImage
              src={message.from.picture || placeholderImage}
              style={{
                opacity: nextMessage?.from?.id === message.from.id ? 0 : 1,
              }}
              onClick={() => navigate(`/app/user/${message.from.id}`)}
            />
          )}
          <Message isOther={isOther}>{message.value}</Message>
        </MessageContainer>
      )
    })

    const pendingMessageArr = pendingMessages.map(message => (
      <Message key={message.id} style={{ opacity: 0.5 }}>
        {message.value}
      </Message>
    ))

    return [...messageArr, ...pendingMessageArr]
  }

  const handleBackClick = () => {
    navigate('/app/chat')
  }

  if (!roomDetails) return null

  const handleRoomNameClick = () => {
    if (roomDetails.participants.length === 2) {
      const otherUserID = roomDetails.participants.filter(
        participant => participant.id !== currentUser.id
      )[0].id
      navigate(`/app/user/${otherUserID}`)
    }
  }

  return (
    <Container
      style={{ borderRadius: isMobile && 0 }}
      onClick={handleContainerFocus}
    >
      {isMobile && <NoGlobalScroll />}
      <ChatHeader>
        {isMobile && (
          <BackButton className="icon-chevron-left" onClick={handleBackClick} />
        )}
        <ChatHeaderInfo onClick={handleRoomNameClick}>
          <img src={roomDetails.picture || placeholderImage} alt="profile" />
          <h4>{roomDetails.name}</h4>
        </ChatHeaderInfo>
      </ChatHeader>
      <ChatMessages ref={chatRef} onScroll={handleScroll}>
        <Wrapper>
          {!roomDetails.allMessagesLoaded && (
            <LoadMoreText onClick={handleLoadMore}>Load more...</LoadMoreText>
          )}
          {renderChatMessages()}
        </Wrapper>
      </ChatMessages>
      <ChatMessageInput
        placeholder="Message"
        onSubmit={message => {
          dispatch(sendChatMessage({ roomID, message }))
        }}
      />
    </Container>
  )
}

ChatMessageView.propTypes = {
  roomID: PropTypes.string.isRequired, // can be null
}

export default ChatMessageView
