/**
 * @file Projects a modal on screen (single prompt, and submit)
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  transition: transform 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
`

const Content = styled.div`
  width: 400px;
  max-width: 95%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  height: 90%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

const Modal = ({ children, style }) => {
  return (
    <Container
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 0.5 }}
    >
      <Content style={style}>
        <GlobalStyle />
        {children}
      </Content>
    </Container>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

Modal.defaultProps = {
  style: {},
}

export default Modal
