/**
 * @file Past Calls Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { loadHotlineMatches } from '../../actions/match'

import PastCallCard from '../Cards/PastCallCard'
import CallsNav from './CallsNav'
import InfiniteList from '../InfiniteList'
import LoadingCard from '../Cards/LoadingCard'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const Icon = styled.span`
  cursor: pointer;
  padding-right: 10px;
`

const SurveyBox = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
`

const PastCalls = () => {
  const dispatch = useDispatch()

  const hotlineCallMatches = useSelector(
    state => state.match.hotlineCallMatches
  )

  useEffect(() => {
    const id = toast(
      t => (
        <SurveyBox>
          <Icon className="icon-x" onClick={() => toast.dismiss(t.id)} />
          <p>
            How's your call? Tell us
            <a
              href="https://forms.gle/t7gbu9BHQTT5vYvm7"
              target="_blank"
              rel="noopener noreferrer"
              style={{ paddingLeft: 4 }}
            >
              here
            </a>
          </p>
        </SurveyBox>
      ),
      {
        duration: 999999999, // temp fix for a persistent toast
      }
    )

    return () => toast.dismiss(id)
  }, [])

  return (
    <Container>
      <InfiniteList
        items={hotlineCallMatches.ids}
        loadMoreItems={() => dispatch(loadHotlineMatches())}
        canLoadMore={hotlineCallMatches.canLoadMore}
        loading={hotlineCallMatches.loading}
        stickyHeader={<CallsNav />}
        loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
      >
        {item => <PastCallCard id={item} style={{ maxWidth: 400 }} />}
      </InfiniteList>
    </Container>
  )
}

export default PastCalls
