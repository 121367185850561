/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import placeholderImage from '../../images/profilePlaceholder.png'
import hotlineImage from '../../images/hotline.jpeg'
import supperclubImage from '../../images/supperclub.jpeg'
import PlainCard from '../Cards/PlainCard'
import { getSuperpod } from '../../actions/pod'
import ButtonV2 from '../ButtonV2'
import PodVerification from './PodVerification'
import { findVerification } from '../../actions/verification'
import { setAuthSuccessCallback } from '../../actions/auth'
import AuthOverlay from '../auth-v2/authOverlay'
import useQueryString from '../../hooks/useQueryString'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  > * {
    width: 100%;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    margin-bottom: 20px;
    box-shadow: ${({ theme }) =>
      theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  }

  p {
    opacity: 0.75;
  }
`

const TitleRow = styled.div`
  display: flex;
  margin-bottom: 10px;

  h2 {
    margin-right: 15px;
  }
`

const Section = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid
    ${({ theme }) => (theme.isDarkMode ? '#ffffff' : '#000000')}26;

  h3 {
    padding-bottom: 20px;
  }

  p {
    white-space: pre-wrap;
  }
`

const PodMatesImgContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  row-gap: 20px;
`

const UserImg = styled.img`
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  object-fit: cover;
`

const Activity = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  img {
    object-fit: cover;
  }

  > *:is(:first-child) {
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;
    border-radius: 10px;
  }

  > div:is(:nth-child(2)) {
    padding-left: 15px;

    > h3 {
      padding-bottom: 5px;
    }

    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`

const ComingSoonOverlay = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    border-radius: inherit;
    height: 100%;
    width: 100%;
    background-color: #00000032;
    position: absolute;
  }

  > img {
    border-radius: inherit;
    height: 100%;
    width: 100%;
  }

  > h3 {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #ffffff;
  }
`

const CTA = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`

const PodInfo = ({ name }) => {
  const dispatch = useDispatch()
  const { verifying } = useQueryString()
  const [showPodVerification, setShowPodVerification] = useState(false)
  const [showAuth, setShowAuth] = useState(false)
  const accessToken = useSelector(state => state.auth.accessToken)
  const superpod = useSelector(
    state => state.pod.superpodDetails[state.pod.nameToSuperpod[name]]
  )
  const userInPod = useSelector(
    state => state.auth.user?.superpods?.filter(s => s.name === name).length > 0
  )
  const podVerification = useSelector(state =>
    Object.values(state.verification.details).find(
      v => v.value === name && v.type === 'superpod'
    )
  )

  const renderPodMates = () =>
    superpod.users?.sample.map(curr => (
      <UserImg
        src={curr.picture}
        key={curr.id}
        onClick={() => navigate(`/app/user/${curr.id}`)}
      />
    ))

  useEffect(() => {
    dispatch(getSuperpod(name))
    dispatch(findVerification({ type: 'superpod', value: name }))
  }, [name])

  useEffect(() => {
    setShowPodVerification(!!verifying)
  }, [verifying])

  useEffect(() => {
    if (superpod?.notFound) navigate('/app/404')
  }, superpod?.notFound)

  const handleJoinPod = () => {
    if (!accessToken) {
      setShowAuth(true)
      dispatch(
        setAuthSuccessCallback(() => {
          navigate(`${window.location.pathname}?verifying=true`, {
            replace: true,
          })
        })
      )
    } else {
      navigate(`${window.location.pathname}?verifying=true`, { replace: true })
    }
  }

  const handleShare = () => {
    if (navigator?.share) {
      navigator.share({ url: window.location.href })
    } else {
      navigator.clipboard.writeText(window.location.href)
      toast.success('Link Copied!')
    }
  }

  const renderTitleButton = () => {
    if (userInPod) {
      return (
        <ButtonV2 size="small" type="secondary" onClick={() => {}}>
          Joined
        </ButtonV2>
      )
    }

    if (podVerification?.status === 'ACTIVE') {
      return (
        <ButtonV2 disabled size="small" type="primary" onClick={() => {}}>
          Pending
        </ButtonV2>
      )
    }

    return (
      <ButtonV2 size="small" type="primary" onClick={handleJoinPod}>
        Join
      </ButtonV2>
    )
  }

  const renderBigJoinButton = () => {
    if (userInPod) return <></>
    if (podVerification?.status === 'ACTIVE') {
      return (
        <ButtonV2 disabled onClick={() => {}}>
          Verification Pending
        </ButtonV2>
      )
    }

    return <ButtonV2 onClick={handleJoinPod}>Join The Pod</ButtonV2>
  }

  if (!superpod) return null

  if (showAuth) return <AuthOverlay />

  if (showPodVerification)
    return (
      <PodVerification
        superpod={superpod}
        hideVerification={() =>
          navigate(window.location.pathname, { replace: true })
        }
      />
    )

  return (
    <PlainCard style={{ maxWidth: 400 }}>
      <Container>
        <Title>
          <img src={superpod.picture || placeholderImage} alt="superpod" />
          <TitleRow>
            <h2>{superpod.displayName}</h2>
            {renderTitleButton()}
          </TitleRow>
          <p>{superpod.description}</p>
        </Title>
        <Section>
          <h3>Pod Mates</h3>
          <PodMatesImgContainer>{renderPodMates()}</PodMatesImgContainer>
        </Section>
        <Section>
          <h3>Activities</h3>
          <Activity>
            <img
              src={hotlineImage}
              style={{ objectPosition: 'left' }}
              alt="hotline"
            />
            <div>
              <h3>Hotlines</h3>
              <p>
                1:1 calls. Pick up the phone and you’ll meet someone else in the
                pod weekly.
              </p>
            </div>
          </Activity>
          <Activity>
            <ComingSoonOverlay>
              <div />
              <img src={supperclubImage} alt="supperclub" />
              <h3>Coming Soon</h3>
            </ComingSoonOverlay>
            <div>
              <h3>Supper Clubs</h3>
              <p>
                Meet your pod IRL over the dinner table with some of our
                favorite restaurant partners.
              </p>
            </div>
          </Activity>
        </Section>
        <CTA>
          {renderBigJoinButton()}
          <ButtonV2 type="secondary" onClick={handleShare}>
            Share
          </ButtonV2>
        </CTA>
      </Container>
    </PlainCard>
  )
}

PodInfo.propTypes = { name: PropTypes.string }

PodInfo.defaultProps = { name: '' }

export default PodInfo
