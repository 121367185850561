/**
 * @file Chat Type Toggles
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  min-height: 38px;
  background-color: ${({ theme }) => theme.Primary};
`

const Tab = styled.div`
  background-color: ${props =>
    props.active ? props.theme.Secondary : 'transparent'};
  box-shadow: ${({ theme, active }) =>
    !theme.isDarkMode && active ? '0px 2px 8px rgba(0, 0, 0, 0.15)' : 'unset'};
  border-radius: 20px;
  padding: 8px 0px;
  flex: 1;
  text-align: center;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
`

const ChatTypeToggle = ({ chatType, onChange }) => {
  const handleClick = e => {
    onChange(e.target.dataset.chattype)
  }

  return (
    <Container onClick={handleClick}>
      <Tab active={chatType === 'default' ? 1 : 0} data-chattype="default">
        People
      </Tab>
      <Tab active={chatType === 'event' ? 1 : 0} data-chattype="event">
        Events
      </Tab>
    </Container>
  )
}

ChatTypeToggle.propTypes = {
  chatType: PropTypes.oneOf(['default', 'event']).isRequired,
  onChange: PropTypes.func,
}

ChatTypeToggle.defaultProps = {
  onChange: () => {},
}

export default ChatTypeToggle
