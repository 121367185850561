/**
 * @file New version of the button, all instances of the old button to be replaced by this
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DefaultButton = styled.button`
  border-radius: 100px;
  outline: none;
  border: 0;
  padding: 10px 40px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  background-color: ${({ theme, type }) =>
    type === 'primary' ? theme.Accent : 'unset'};
  color: ${({ theme, type }) => (type === 'primary' ? 'white' : theme.Accent)};
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ theme, type }) =>
    type === 'secondary' ? theme.Accent : 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const SmallButton = styled.button`
  border-radius: 10px;
  outline: none;
  padding: 5px 15px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  background-color: ${({ theme, type }) =>
    type === 'primary' ? theme.Accent : 'unset'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, type }) =>
    type === 'secondary' ? theme.Accent : 'transparent'};
  color: ${({ theme, type }) => (type === 'primary' ? 'white' : theme.Accent)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const ButtonV2 = ({ size, type, disabled, children, onClick }) => {
  switch (size) {
    case 'default':
      return (
        <DefaultButton disabled={disabled} type={type} onClick={onClick}>
          <h3>{children}</h3>
        </DefaultButton>
      )
    case 'small':
      return (
        <SmallButton disabled={disabled} type={type} onClick={onClick}>
          <h4>{children}</h4>
        </SmallButton>
      )
    default:
      return null
  }
}

ButtonV2.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
  type: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
}

ButtonV2.defaultProps = {
  size: 'default',
  type: 'primary',
  disabled: false,
}

export default ButtonV2
