/**
 * @file Loading page of onboarding
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import ImageInputV2 from '../ImageInputV2'

const Page0 = ({ control }) => {
  const currentFormData = useSelector(
    state => state.user.onboardingUserStore.formData
  )

  return (
    <>
      <h3 style={{ width: '100%', textAlign: 'center' }}>
        Add a Profile Picture
      </h3>
      <Controller
        as={
          <ImageInputV2
            resizeWidth={600}
            resizeHeight={600}
            previewHeight={200}
            previewWidth={200}
          />
        }
        name="picture"
        control={control}
        defaultValue={currentFormData.picture || ''}
        rules={{ required: true }}
      />
    </>
  )
}

Page0.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Page0
