/**
 * @file Stripe payment for the specified event
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

const Container = styled.div``

const Error = styled.h4`
  color: 'red';
`

const StripePrebuilt = () => {
  const [error, setError] = useState('')
  const sessionID = useSelector(state => state.stripe.sessionID)

  useEffect(() => {
    const run = async () => {
      const stripe = await stripePromise

      const result = await stripe.redirectToCheckout({ sessionId: sessionID })

      if (result.error) {
        console.error(result.error)
        setError(result.error.message)
      }
    }

    run()
  }, [sessionID])

  if (!sessionID) return null
  return <Container>{error && <Error>{error}</Error>}</Container>
}

export default StripePrebuilt
