/**
 * @file Reset Password Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PureInput } from '../input'
import Button from '../button'
import { changePassword } from '../../actions/auth'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }
`

const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.Accent};
  padding-bottom: 10px;
`

const ResetPassword = () => {
  const dispatch = useDispatch()
  const { handleSubmit, register, errors, formState, watch } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const authError = useSelector(state => state.auth.authError)
  const resetPasswordSuccess = useSelector(
    state => state.auth.resetPasswordSuccess
  )

  const onSubmit = values => {
    dispatch(changePassword({ newPassword: values.newPassword }))
  }

  const handleLoginAgain = () => {
    window.location.reload()
  }

  if (resetPasswordSuccess) {
    return (
      <Form>
        <div style={{ marginBottom: 80 }}>
          <h2>Reset Password</h2>
        </div>
        <h4 style={{ marginBottom: 0 }}>Your password has been reset.</h4>
        <h4>Please login again.</h4>
        <Button onClick={handleLoginAgain}>Login Again</Button>
      </Form>
    )
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Reset Password</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>Enter your new password</h4>
      <PureInput
        placeholder="New Password"
        name="newPassword"
        type="password"
        autoComplete="new-password"
        ref={register({
          required: true,
          validate: value =>
            value.match(/^(?=.*\d)(?=.*[A-Za-z]).{8,}$/) ||
            'Password needs to have a minimum of 8 letters and a number',
        })}
        style={{
          paddingRight: 15,
          textAlign: 'center',
          border: errors.newPassword?.message && '2px solid red',
        }}
      />
      {errors.newPassword?.message && (
        <ErrorMsg>{errors.newPassword?.message}</ErrorMsg>
      )}
      <PureInput
        placeholder="Confirm New Password"
        name="confirmNewPassword"
        type="password"
        autoComplete="new-password"
        ref={register({
          required: true,
          validate: value => value === watch('newPassword'),
        })}
        style={{ paddingRight: 15, textAlign: 'center' }}
      />
      {authError && <ErrorMsg>{authError}</ErrorMsg>}
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
    </Form>
  )
}

export default ResetPassword
