/**
 * @file Sections for edit profile
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactDatePicker from 'react-datepicker'
import Input, { PureInput } from '../../input'

const Container = styled.div`
  width: 100%;
  padding: 10px 0;

  > h3 {
    padding-bottom: 5px;
  }
`

const EditProfileSection = ({ title, placeholder, onChange, value, type }) => {
  const renderInput = () => {
    switch (type) {
      case 'date':
        return (
          <ReactDatePicker
            customInput={
              <PureInput style={{ textAlign: 'left', width: '100%' }} />
            }
            onFocus={e => e.target.blur()}
            withPortal
            placeholderText={placeholder}
            onChange={birthDate => onChange(birthDate)}
            selected={value ? new Date(value) : null}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd MMMM yyyy"
          />
        )
      default:
        return (
          <Input
            placeholder={placeholder}
            style={{ width: '100%' }}
            onChange={onChange}
            value={value}
          />
        )
    }
  }

  return (
    <Container>
      <h3>{title}</h3>
      {renderInput()}
    </Container>
  )
}

EditProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', '']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
}

EditProfileSection.defaultProps = {
  type: '',
  placeholder: 'Type Something',
  onChange: () => {},
}

export default EditProfileSection
