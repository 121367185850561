/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { initEvents, loadPastEvents } from '../../actions/events'

import SEO from '../seo'
import Can from '../../casl/Can'
import Card from './card'
import PastAndUpcomingNav from './pastAndUpcomingNav'
import FloatingActionButton from '../FloatingActionButton'

const LoadMoreContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 40px;
`

const LoadMoreText = styled.h4`
  cursor: pointer;
`

const PastEvents = () => {
  const dispatch = useDispatch()
  const pastEvents = useSelector(state => state.events.pastEvents)
  const canLoadMore = useSelector(state => state.events.canLoadMorePastEvents)

  useEffect(() => {
    dispatch(initEvents())
  }, [dispatch])

  const handleLoadMore = () => {
    dispatch(loadPastEvents())
  }

  const renderPastEvents = () =>
    pastEvents.map(event => (
      <Card
        key={event.id}
        id={event.id}
        title={event.title || event.type}
        number={event.number}
        date={event.startDate}
        participants={event.participants}
        picture={event.picture}
      />
    ))

  return (
    <>
      <SEO title="Events" description="Your past events" />
      <PastAndUpcomingNav />
      <div style={{ paddingTop: 75 }} />
      {pastEvents.length > 0 ? (
        renderPastEvents()
      ) : (
        <p style={{ paddingTop: 20 }}>No events to show</p>
      )}
      {canLoadMore && (
        <LoadMoreContainer>
          <LoadMoreText onClick={handleLoadMore}>Load More</LoadMoreText>
        </LoadMoreContainer>
      )}
      <div style={{ paddingBottom: 5 }} />
      <Can I="create" an="Event">
        <FloatingActionButton
          onClick={() => {
            navigate('/app/events/create')
          }}
        >
          <span className="icon-plus" style={{ fontSize: 25 }} />
        </FloatingActionButton>
      </Can>
    </>
  )
}

export default PastEvents
