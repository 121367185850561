/**
 * @file Creates an Event
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Redirect } from '@reach/router'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Can from '../../../casl/Can'
import Page0 from './page0'
import Page1 from './page1'
import Page2 from './page2'
import Page3 from './page3'
import Page4 from './page4'
import FormProgressBar from '../../FormProgressBar'
import Button from '../../button'
import {
  createNewEvent,
  updateCreateEventStore,
  updateEvent,
  getEventDetails,
  resetCreateEventStore,
} from '../../../actions/events'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-height: 100%;
  position: relative;
`

const TitleBar = styled.div`
  display: flex;
  align-items: center;
`

const BackButton = styled.span`
  font-size: 22px;
  position: absolute;
  left: 0;
  cursor: pointer;
  transition: 0.25s ease;

  :hover {
    opacity: 0.6;
  }
`

const Form = styled.form`
  h3 {
    margin-bottom: 7px;
  }

  input {
    margin-bottom: 20px;
  }

  label {
    margin-bottom: 10px;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 50px;
`

const CreateEvent = ({ id }) => {
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState, watch, reset } = useForm({
    mode: 'onChange',
  })
  const { segments, progress } = useSelector(
    state => state.events.createEventStore
  )
  const eventDetails = useSelector(state => state.events.eventDetails[id])

  useEffect(() => {
    if (id) {
      dispatch(resetCreateEventStore())
      dispatch(getEventDetails(id))
    }
  }, [])

  useEffect(() => {
    if (eventDetails)
      reset({
        title: eventDetails?.title || '',
        startDate: eventDetails?.startDate
          ? new Date(eventDetails.startDate)
          : '',
        endDate: eventDetails?.endDate ? new Date(eventDetails.endDate) : '',
        maxParticipants: eventDetails?.maxParticipants || '',
        location: eventDetails?.location || '',
        online: eventDetails?.online,
        link: eventDetails?.link || '',
        about: eventDetails?.about || '',
        additionalInfoQuestion: eventDetails?.additionalInfoQuestion || '',
        picture: eventDetails?.picture || '',
        showGuestList: eventDetails?.settings?.showGuestList || true,
        showFunFacts: eventDetails?.settings?.showFunFacts || true,
        showProfessions: eventDetails?.settings?.showProfessions || true,
        showQuestion: eventDetails?.settings?.showQuestion || true,
      })
  }, [eventDetails])

  const renderInputs = () => {
    switch (progress) {
      case 0:
        return <Page0 register={register} control={control} watch={watch} />
      case 1:
        return <Page1 register={register} watch={watch} />
      case 2:
        return <Page2 register={register} />
      case 3:
        return <Page3 control={control} />
      case 4:
        return <Page4 register={register} />
      default:
        return null
    }
  }

  const handleBackClick = () => {
    dispatch(updateCreateEventStore({ progress: progress - 1 }))
  }

  const onSubmit = data => {
    dispatch(updateCreateEventStore({ progress: progress + 1, data }))
    if (progress === segments - 1) {
      if (id) dispatch(updateEvent(id))
      else dispatch(createNewEvent())
    }
  }

  // todo. have to wait till auth is loaded...

  return (
    <Can I="create" an="Event" passThrough>
      {allowed =>
        allowed ? (
          <Container>
            <TitleBar>
              {progress > 0 && (
                <BackButton
                  className="icon-chevron-left"
                  onClick={handleBackClick}
                />
              )}
              {id ? <h2>Edit Event</h2> : <h2>Create An Event</h2>}
            </TitleBar>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {renderInputs()}
              <div style={{ flex: 1 }} />
              <FormProgressBar segments={segments} progress={progress} />
              <Button
                style={{ margin: '10px 0 40px 0' }}
                disabled={!formState.isValid}
              >
                {progress === segments - 1 ? 'Create Event' : 'Next'}
              </Button>
            </Form>
          </Container>
        ) : (
          <Redirect to="/app/404" />
        )
      }
    </Can>
  )
}

CreateEvent.propTypes = {
  id: PropTypes.string,
}

CreateEvent.defaultProps = {
  id: '',
}

export default CreateEvent
