/**
 * @file Upcoming call cards
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import ButtonV2 from '../ButtonV2'
import { registerCall, unregisterCall } from '../../actions/calls'

const Container = styled.div`
  padding: 20px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  width: 100%;
`

const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Tag = styled.h5`
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.Tertiary};
  margin-bottom: 5px;
`

const callSelector = createSelector(
  state => state.calls.hotlineDetails,
  state => state.auth.user?.id,
  (state, id) => state.calls.callDetails[id],
  (hotlineDetails, currentUserID, currentCallDetail) => ({
    ...currentCallDetail,
    hotline: hotlineDetails[currentCallDetail.hotline],
    userRegistered: currentCallDetail.registered.includes(currentUserID),
  })
)

const UpcomingCallCard = ({ id, style }) => {
  const dispatch = useDispatch()
  const call = useSelector(state => callSelector(state, id))

  const handleToggleRegister = () =>
    dispatch(
      call.userRegistered ? unregisterCall(call.id) : registerCall(call.id)
    )

  return (
    <Container style={style}>
      <MainInfo>
        <div>
          {call.recursEvery && <Tag>Recurring</Tag>}
          <h5>{dayjs(call.startDate).format('ddd, h:mm a')}</h5>
          <h3>{call.hotline.title}</h3>
        </div>
        <ButtonV2
          disabled={call.loading}
          size="small"
          onClick={handleToggleRegister}
          type={call.userRegistered ? 'secondary' : 'primary'}
        >
          {call.userRegistered ? 'Registered' : 'Register'}
        </ButtonV2>
      </MainInfo>
    </Container>
  )
}

UpcomingCallCard.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

UpcomingCallCard.defaultProps = { style: {} }

export default UpcomingCallCard
