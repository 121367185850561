/**
 * @file Card to showcase each event
 * @author Alwyn Tan
 */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import placeholderImage from '../../images/profilePlaceholder.png'

const Container = styled.div`
  height: 255px;
  width: 100%;
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 10px;
  align-self: center;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const InfoContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const TitleImgContainer = styled.img`
  height: 120px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

const Participants = styled.div`
  margin-top: 10px;
`

const ParticipantContainer = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 10px;
`

const Participant = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
`

const ParticipantOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`

const Card = ({ date, title, participants, picture, id }) => {
  const participantsRef = useRef(null)
  const isTinyMobile = useMediaQuery({ query: '(max-width: 319px)' })
  const isSmallMobile = useMediaQuery({ query: '(max-width: 379px)' })
  const isMediumMobile = useMediaQuery({ query: '(max-width: 439px)' })

  const renderParticipants = () => {
    let renderCount = 0

    // min rendercount is 4
    if (isTinyMobile) renderCount = 3
    else if (isSmallMobile) renderCount = 4
    else if (isMediumMobile) renderCount = 5
    else renderCount = 6

    const rendered = []

    for (let i = 0; i < participants.length; i++) {
      if (i >= renderCount) break
      // if there's more but we are not rendering them
      if (i === renderCount - 1 && i < participants.length - 1) {
        let remaining = participants.length - 1 - i
        remaining = remaining > 99 ? 99 : remaining

        rendered.push(
          <ParticipantContainer key={participants[i].id}>
            <Participant
              src={participants[i].picture || placeholderImage}
              style={{ opacity: 0.25 }}
            />
            <ParticipantOverlay>
              <h4>+{remaining}</h4>
            </ParticipantOverlay>
          </ParticipantContainer>
        )
      } else {
        rendered.push(
          <ParticipantContainer key={participants[i].id}>
            <Participant src={participants[i].picture || placeholderImage} />
          </ParticipantContainer>
        )
      }
    }

    return rendered
  }

  const handleCardClick = () => {
    navigate(`/app/events/${id}`)
  }

  return (
    <Container onClick={handleCardClick}>
      <TitleImgContainer src={picture || placeholderImage} />
      <InfoContainer>
        <h6>{dayjs(date).format('MMMM D')}</h6>
        <h3>{title}</h3>
        <Participants ref={participantsRef}>
          {renderParticipants()}
        </Participants>
      </InfoContainer>
    </Container>
  )
}

Card.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  picture: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default Card
