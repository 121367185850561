/**
 * @file Set profile picture
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { PureInput } from '../input'

dayjs.extend(customParseFormat)

const Page1 = ({ register }) => {
  const currentFormData = useSelector(
    state => state.user.onboardingUserStore.formData
  )
  const user = useSelector(state => state.auth.user)

  return (
    <>
      <h3>Your Name</h3>
      <PureInput
        name="name"
        placeholder="Elaine Cheng"
        defaultValue={currentFormData.name || user.name || ''}
        ref={register({ required: true })}
      />
      <h3>Your Email</h3>
      <PureInput
        name="email"
        placeholder="info@soloclub.app"
        defaultValue={currentFormData.email || user.email || ''}
        ref={register({ required: true })}
      />
      <h3>A Fun Fact About You</h3>
      <PureInput
        name="funFact"
        placeholder="I know morse code"
        defaultValue={currentFormData.funFact || user.funFact || ''}
        ref={register({ required: true })}
      />
      <h3>Your Birthday</h3>
      <PureInput
        name="birthDate"
        defaultValue={
          currentFormData.birthDate ||
          (user.birthDate && dayjs(user.birthDate).format('YYYY-MM-DD')) ||
          ''
        }
        ref={register({
          required: true,
          validate: value => dayjs(value).isBefore(dayjs()),
        })}
        type="date"
      />
      <h3>Your Profession</h3>
      <PureInput
        name="profession"
        placeholder="Digital Marketer"
        defaultValue={currentFormData.profession || user.profession || ''}
        ref={register({ required: true })}
      />
      <h3>Your Zip Code</h3>
      <PureInput
        name="zipCode"
        placeholder="90019"
        type="number"
        defaultValue={currentFormData.zipCode || user.zipCode || ''}
        ref={register({ required: true })}
      />
    </>
  )
}

Page1.propTypes = {
  register: PropTypes.func.isRequired,
}

export default Page1
