/**
 * @file Forgot password page
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { sendVerificationCode, verifyCode } from '../../actions/auth'
import { PureInput } from '../input'
import Button from '../button'
import ResetPassword from './resetPassword'

dayjs.extend(duration)

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }
`

const AuthError = styled.p`
  color: ${({ theme }) => theme.Accent};
`

const Resend = styled.button`
  color: ${({ theme }) => theme.Accent};
  background-color: transparent;
  outline: none;
  border: 0;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const ForgotPassword = () => {
  const dispatch = useDispatch()

  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const authError = useSelector(state => state.auth.authError)
  const authPhoneNumber = useSelector(state => state.auth.authPhoneNumber)
  const verified = useSelector(state => state.auth.verified)
  const nextResendVerificationDate = useSelector(
    state => state.auth.nextResendVerificationDate
  )
  const [timeLeft, setTimeLeft] = useState(null)

  useEffect(() => {
    const updateTimeLeft = () => {
      if (
        nextResendVerificationDate &&
        dayjs(nextResendVerificationDate).isAfter(dayjs())
      ) {
        // TODO: dayjs is releasing a duration.format soon. Change to that once it's out
        const d = dayjs.duration(
          dayjs(nextResendVerificationDate).diff(dayjs())
        )
        setTimeLeft(dayjs(d.asMilliseconds()).format('m:ss'))
      } else {
        setTimeLeft(null)
      }
    }

    const countdownTimer = setInterval(updateTimeLeft, 1000)
    updateTimeLeft()

    return () => {
      clearInterval(countdownTimer)
    }
  }, [nextResendVerificationDate])

  const onSubmit = values => {
    dispatch(verifyCode(values.verificationCode))
  }

  if (verified) return <ResetPassword />

  const handleResendCode = () => {
    dispatch(sendVerificationCode())
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Forgot Your Password?</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>
        To help us verify your identity, please enter the code sent to your
        phone number ending in{' '}
        {authPhoneNumber.slice(authPhoneNumber.length - 4)}
      </h4>
      <PureInput
        placeholder="Enter Code"
        name="verificationCode"
        autoComplete="one-time-code"
        pattern="[0-9]*"
        ref={register({
          required: true,
          validate: value => value.length || 'This is required.',
        })}
        style={{ paddingRight: 15, textAlign: 'center' }}
      />
      {authError && <AuthError>{authError}</AuthError>}
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
      <h5 style={{ fontSize: 14 }}>
        Didn't receive a code?{' '}
        <Resend onClick={handleResendCode} disabled={timeLeft}>
          Resend Code{timeLeft && ` (${timeLeft})`}
        </Resend>
      </h5>
    </Form>
  )
}

export default ForgotPassword
