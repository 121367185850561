/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CallsNav from './CallsNav'
import LoadingCard from '../Cards/LoadingCard'
import { loadUpcomingHotlineCalls } from '../../actions/calls'
import InfiniteList from '../InfiniteList'
import UpcomingCallCard from '../Cards/UpcomingCallCard'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const UpcomingCalls = () => {
  const dispatch = useDispatch()
  const upcomingCalls = useSelector(state => state.calls.upcoming)

  return (
    <Container>
      <InfiniteList
        items={upcomingCalls.ids}
        loadMoreItems={() => dispatch(loadUpcomingHotlineCalls())}
        canLoadMore={upcomingCalls.canLoadMore}
        loading={upcomingCalls.loading}
        stickyHeader={<CallsNav style={{ maxWidth: 400 }} />}
        loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
      >
        {item => <UpcomingCallCard id={item} style={{ maxWidth: 400 }} />}
      </InfiniteList>
    </Container>
  )
}

export default UpcomingCalls
