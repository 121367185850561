/**
 * @file Settings Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import AppLayout from '../appLayout'
import Switch from '../Switch'
import { updateUser } from '../../actions/user'

const Setting = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Settings = () => {
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.auth.user.settings)

  const handleSettingsUpdate = (type, value) => {
    dispatch(updateUser({ settings: { [type]: value } }))
  }

  return (
    <AppLayout>
      <h2 style={{ marginBottom: 15 }}>Settings</h2>
      <h3 style={{ marginBottom: 10 }}>Display Settings</h3>
      <Setting>
        <h4>Dark Mode</h4>
        <Switch
          onChange={value => handleSettingsUpdate('darkMode', value)}
          checked={userSettings?.darkMode}
          aria-label="dark mode toggle switch"
        />
      </Setting>
    </AppLayout>
  )
}

export default Settings
