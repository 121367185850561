/**
 * @file Pod Verification Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import placeholderImage from '../../images/profilePlaceholder.png'
import ButtonV2 from '../ButtonV2'
import { requestSuperpodVerification } from '../../actions/verification'
import { updateUser } from '../../actions/user'
import { PureInput } from '../input'
import PlainCard from '../Cards/PlainCard'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SmallPodInfo = styled.div`
  display: flex;
  margin-bottom: 30px;

  img {
    object-fit: cover;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-right: 10px;
    box-shadow: ${({ theme }) =>
      theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  }

  p {
    padding-top: 2px;
  }
`

const TitleRow = styled.div`
  text-align: center;
  margin-bottom: 100px;

  p {
    padding-top: 5px;
  }
`

const InputsContainer = styled.div`
  width: 100%;
  margin-bottom: 100px;

  h3 {
    padding-bottom: 5px;
  }

  input {
    width: 100%;
  }

  > div:not(:first-child) {
    margin-top: 20px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const LinkedInContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  > p {
    margin-right: 5px;
  }
`

const LINKEDIN_PREFIX = 'https://linkedin.com/in/'

const PodVerification = ({ superpod, hideVerification }) => {
  const dispatch = useDispatch()
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const currentUser = useSelector(state => state.auth.user)

  const onSubmit = data => {
    // update and request verification
    dispatch(
      updateUser({
        ...data,
        ...(data.linkedIn
          ? { linkedIn: `${LINKEDIN_PREFIX}${data.linkedIn}` }
          : {}),
      })
    )
    dispatch(requestSuperpodVerification({ superpodName: superpod.name }))
    hideVerification()
  }

  useEffect(() => {
    // check if things exist
    let hasAllReqInfo = true
    for (const generalReq of Object.keys(superpod.verification.general) || []) {
      if (!currentUser[generalReq]) {
        hasAllReqInfo = false
        break
      }
    }

    if (hasAllReqInfo) {
      dispatch(requestSuperpodVerification({ superpodName: superpod.name }))
      hideVerification()
    }
  }, [])

  if (!superpod) navigate('/app/404')

  return (
    <PlainCard style={{ maxWidth: 400, padding: '40px 20px' }}>
      <Container>
        <SmallPodInfo>
          <img src={superpod.picture || placeholderImage} alt="superpod" />
          <div>
            <h3>{superpod.displayName}</h3>
            <p>
              {superpod.users.count} member{superpod.users.count > 1 && 's'}
            </p>
          </div>
        </SmallPodInfo>
        <TitleRow>
          <h2>Additional Info Required</h2>
          <p>
            Please answer these questions to help us verify your pod request.
          </p>
        </TitleRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputsContainer>
            {superpod.verification.general.email && !currentUser.email && (
              <div>
                <h3>Your Email</h3>
                <PureInput
                  name="email"
                  placeholder="info@soloclub.app"
                  ref={register({ required: true })}
                />
              </div>
            )}
            {superpod.verification.general.linkedIn && !currentUser.linkedIn && (
              <div>
                <h3>Your LinkedIn</h3>
                <LinkedInContainer>
                  <p>{LINKEDIN_PREFIX}</p>
                  <PureInput
                    name="linkedIn"
                    style={{ textTransform: 'lowercase', width: '100%' }}
                    placeholder="alwyn-tan"
                    autocapitalize="none"
                    ref={register({ required: true })}
                  />
                </LinkedInContainer>
              </div>
            )}
          </InputsContainer>
          <ButtonV2 disabled={!formState.isValid} onClick={() => {}}>
            Submit
          </ButtonV2>
        </Form>
      </Container>
    </PlainCard>
  )
}

PodVerification.propTypes = {
  superpod: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hideVerification: PropTypes.func.isRequired,
}

export default PodVerification
