/**
 * @file Home for the app (the explore page)
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { redirectFromParams } from '../../utils/redirect'

const AppHome = () => {
  useEffect(() => {
    // attempt to auto redirect on load, if there is a redirect param when visiting this page
    redirectFromParams('/app/events')
  })

  return <></>
}

export default AppHome
