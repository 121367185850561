/**
 * @file Rating Component
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  color: ${({ theme }) => theme.Accent};
  font-size: 20px;
  position: relative;
  cursor: pointer;
`

const Filled = styled.div`
  position: absolute;

  > button {
    display: inline-block;
    outline: none;
    border: 0;
    color: ${({ theme }) => theme.Accent};
    font-size: 20px;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
`

const starTemplate = [1, 2, 3, 4, 5]

const Rating = ({ rating, onRatingChange }) => {
  const [stars, setStars] = useState(0)

  useEffect(() => {
    setStars(rating)
  }, [rating])

  const renderFilledStars = () =>
    starTemplate.map(star => (
      <button
        key={`filled-star-${star}`}
        onClick={() => onRatingChange(star)}
        type="button"
      >
        <span
          onMouseEnter={() => setStars(star)}
          className="icon-filled-star"
          key={`star-${star}`}
          style={{ opacity: stars >= star ? 1 : 0 }}
        />
      </button>
    ))

  const renderStars = () =>
    starTemplate.map(star => (
      <span className="icon-star" key={`star-${star}`} />
    ))

  return (
    <Container>
      <Filled onMouseLeave={() => setStars(rating)}>
        {renderFilledStars()}
      </Filled>
      <div>{renderStars()}</div>
    </Container>
  )
}

Rating.propTypes = {
  rating: PropTypes.number,
  onRatingChange: PropTypes.func,
}

Rating.defaultProps = {
  rating: 0,
  onRatingChange: () => {},
}

export default Rating
