/**
 * @file Edit profile page
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import AppLayout from '../../appLayout'
import EditProfileSection from './editProfileSection'
import EditProfileItem from './editProfileItem'

import Button from '../../button'
import ImageInput from '../../imageInput'
import { updateUser } from '../../../actions/user'

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 500px;
`

const BackButton = styled.div`
  font-size: 22px;
  cursor: pointer;
  margin-bottom: 25px;
`

const Index = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.user.loading)

  const [edited, setEdited] = useState(false)
  const [tempUser, setTempUser] = useState({
    picture: user.picture || '',
    quote: user.quote || '',
    name: user.name || '',
    profession: user.profession || '',
    location: user.location || '',
    funFact: user.funFact || '',
    birthDate: user.birthDate || '',
  })
  const [profilePicPreview, setProfilePicPreview] = useState('')

  const handleChange = obj => {
    setEdited(true)
    setTempUser({ ...tempUser, ...obj })
  }

  const handleSave = () => {
    // send to server
    dispatch(updateUser(tempUser))
  }

  useEffect(() => {
    let objectURL
    if (typeof tempUser.picture === 'object') {
      objectURL = URL.createObjectURL(tempUser.picture)
      setProfilePicPreview(objectURL)
    }

    return () => URL.revokeObjectURL(objectURL)
  }, [tempUser.picture])

  return (
    <AppLayout hideHeaderAndPadding>
      <Center>
        <Container>
          <BackButton
            className="icon-chevron-left"
            onClick={() => navigate(`/app/user/${user.id}`)}
          />
          <EditProfileSection hideBorder>
            <h3>Profile Picture</h3>
            <ImageInput
              currentPicture={profilePicPreview || String(tempUser.picture)}
              onChange={file => handleChange({ picture: file })}
            />
          </EditProfileSection>
          <EditProfileSection key="quote">
            <EditProfileItem
              title="Quote"
              placeholder="Enter a quote"
              onChange={e => handleChange({ quote: e.target.value })}
              value={tempUser.quote}
            />
          </EditProfileSection>
          <EditProfileSection key="names">
            <EditProfileItem
              title="Your Name"
              placeholder="Enter Your Name"
              onChange={e => handleChange({ name: e.target.value })}
              value={tempUser.name}
            />
          </EditProfileSection>
          <EditProfileSection key="profession">
            <EditProfileItem
              title="Profession/Field of Interest"
              placeholder="Enter profession"
              onChange={e => handleChange({ profession: e.target.value })}
              value={tempUser.profession}
            />
          </EditProfileSection>
          <EditProfileSection key="location">
            <EditProfileItem
              title="Location"
              placeholder="Enter location"
              onChange={e => handleChange({ location: e.target.value })}
              value={tempUser.location}
            />
          </EditProfileSection>
          <EditProfileSection key="funFact">
            <EditProfileItem
              title="Fun Fact"
              placeholder="Enter fun fact"
              onChange={e => handleChange({ funFact: e.target.value })}
              value={tempUser.funFact}
            />
          </EditProfileSection>
          <EditProfileSection key="birthday">
            <EditProfileItem
              title="Birthday"
              placeholder="Enter birthday"
              type="date"
              onChange={birthDate => handleChange({ birthDate })}
              value={tempUser.birthDate}
            />
          </EditProfileSection>
          <Button
            style={{ width: '100%', margin: '20px 0' }}
            disabled={!edited || loading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Container>
      </Center>
    </AppLayout>
  )
}

export default Index
