/**
 * @file App's 404 page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import AppLayout from './appLayout'
import SEO from './seo'

const Container = styled.div`
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  padding-top: 20px;
  text-decoration: none;
`

const App404 = () => {
  return (
    <AppLayout>
      <SEO title="404: Not found" />
      <Container>
        <h2>Page Not Found :(</h2>
        <StyledLink to="/app">Back to App Home</StyledLink>
      </Container>
    </AppLayout>
  )
}

export default App404
