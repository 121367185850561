/**
 * @file The login gate: request users to input their number
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { Controller, useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import useQueryString from '../../hooks/useQueryString'
import { PureInput } from '../input'
import Button from '../button'
import { getAccount } from '../../actions/auth'
import 'react-phone-number-input/style.css'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }

  .PhoneInput {
    input {
      padding-left: 55px;
    }
    position: relative;
  }

  .PhoneInputCountry {
    position: absolute;
    top: calc(50% - 7.5px);
    left: 15px;
  }

  --PhoneInputCountryFlag-borderColor: transparent;
`

const AuthError = styled.p`
  color: ${({ theme }) => theme.Accent};
`

const Gate = () => {
  const dispatch = useDispatch()
  const { p } = useQueryString()
  const { handleSubmit, control, errors, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const authError = useSelector(state => state.auth.authError)

  useEffect(() => {
    if (p) dispatch(getAccount({ phoneNumber: p }))
  }, [p, dispatch])

  const onSubmit = values => {
    dispatch(getAccount({ phoneNumber: values.phoneNumber }))
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: -500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Welcome to Solo</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>What's your number?</h4>
      <Controller
        as={PhoneInput}
        name="phoneNumber"
        control={control}
        rules={{
          required: true,
          validate: value => isMobilePhone(value) || 'Invalid Phone Number',
        }}
        defaultValue=""
        defaultCountry="US"
        inputComponent={PureInput}
        placeholder="Phone Number"
        type="tel"
        autoComplete="tel"
        style={{
          borderRadius: 10,
          border: errors.phoneNumber?.message && '2px solid red',
        }}
      />
      {authError && <AuthError>{authError}</AuthError>}
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
    </Form>
  )
}

export default Gate
