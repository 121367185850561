/**
 * @file Default events page, wraps the past and upcoming events
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import AppLayout from '../appLayout'

const Container = styled.div`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  max-width: 400px;
  height: 100%;

  > div {
    height: 100%;
    min-height: 100%;
  }
`

const Index = ({ children }) => {
  return (
    <AppLayout>
      <Container>{children}</Container>
    </AppLayout>
  )
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Index
