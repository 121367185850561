/**
 * @file Available call cards
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import ButtonV2 from '../ButtonV2'

const Container = styled.div`
  padding: 20px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  width: 100%;
`

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Description = styled.div`
  padding-top: 15px;
`

const AvailableHotlineCard = ({ id, style }) => {
  const hotline = useSelector(state => state.calls.hotlineDetails[id])

  return (
    <Container style={style}>
      <TitleBar>
        <div>
          <h3>{hotline.title}</h3>
        </div>
        <ButtonV2
          size="small"
          onClick={() => navigate(`/app/calls/details/${hotline.id}`)}
        >
          Learn More
        </ButtonV2>
      </TitleBar>
      <Description>
        <p>{hotline.shortDescription}</p>
      </Description>
    </Container>
  )
}

AvailableHotlineCard.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

AvailableHotlineCard.defaultProps = { style: {} }

export default AvailableHotlineCard
