/**
 * @file Sections for edit profile
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  border-top: ${props =>
    props.hideBorder ? 0 : '1px solid rgba(255, 255, 255, 0.4)'};
  margin-top: 20px;

  > h3 {
    padding-bottom: 5px;
  }
`

const EditProfileSection = ({ hideBorder, children }) => {
  return <Container hideBorder={hideBorder}>{children}</Container>
}

EditProfileSection.propTypes = {
  children: PropTypes.node.isRequired,
  hideBorder: PropTypes.bool,
}

EditProfileSection.defaultProps = {
  hideBorder: false,
}

export default EditProfileSection
