/**
 * @file Loading page to show
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  div {
    background-color: ${({ theme }) => theme.Secondary};
    border-radius: 10px;
  }
`

const TitleImg = styled.div`
  width: 100%;
  height: 215px;
  margin-bottom: 20px;
`

const Title = styled.div`
  height: 35px;
  width: 200px;
  margin-bottom: 10px;
`

const TitleItem = styled.span`
  display: flex;

  > div:first-child {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
  }

  > div:last-child {
    height: 40px;
    width: 200px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`

const Heading = styled.div`
  height: 30px;
  width: 150px;
  margin-bottom: 5px;
`

const Para = styled.div`
  height: 150px;
  width: 100%;
  margin-bottom: 10px;
`

const EventLoading = () => {
  return (
    <Container
      animate={{ opacity: 0.5 }}
      transition={{ repeat: Infinity, duration: 0.75, repeatType: 'reverse' }}
    >
      <TitleImg />
      <Title />
      <TitleItem>
        <div />
        <div />
      </TitleItem>
      <TitleItem>
        <div />
        <div />
      </TitleItem>
      <>
        <Divider />
        <Heading />
        <Para />
      </>
      <>
        <Divider />
        <Heading />
        <Para />
      </>
      <>
        <Divider />
        <Heading />
        <Para style={{ marginBottom: 100 }} />
      </>
    </Container>
  )
}

export default EventLoading
