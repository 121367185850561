/**
 * @file Cards to depict individual pictures
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import placeholderImage from '../../../images/profilePlaceholder.png'

const Container = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
`

const Image = styled.img`
  width: 155px;
  height: 155px;
  border-radius: 10px;
  margin-top: 20px;
  object-fit: cover;
  cursor: pointer;

  @media only screen and (max-width: 375px) {
    /* For screens smaller than 360px (eg. iphone SE) */
    width: 135px;
    height: 135px;
  }

  @media only screen and (max-width: 320px) {
    /* For screens smaller than 360px (eg. iphone SE) */
    width: 115px;
    height: 115px;
  }
`

const EventParticipantList = ({ participants }) => {
  const renderParticipants = () =>
    participants.map(participant => (
      <Image
        src={participant.picture || placeholderImage}
        key={participant.id}
        onClick={() => navigate(`/app/user/${participant.id}`)}
      />
    ))

  return <Container>{renderParticipants()}</Container>
}

// key defaults to index

EventParticipantList.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.object),
}

EventParticipantList.defaultProps = {
  participants: [],
}

export default EventParticipantList
