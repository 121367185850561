/**
 * @file Available Calls Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CallsNav from './CallsNav'
import LoadingCard from '../Cards/LoadingCard'
import { loadAvailableHotlines } from '../../actions/calls'
import InfiniteList from '../InfiniteList'
import AvailableHotlineCard from '../Cards/AvailableHotlineCard'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const AvailableCalls = () => {
  const dispatch = useDispatch()
  const availableHotlines = useSelector(state => state.calls.available)

  return (
    <Container>
      <InfiniteList
        items={availableHotlines.ids}
        loadMoreItems={() => dispatch(loadAvailableHotlines())}
        canLoadMore={availableHotlines.canLoadMore}
        loading={availableHotlines.loading}
        stickyHeader={<CallsNav style={{ maxWidth: 400 }} />}
        loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
      >
        {item => <AvailableHotlineCard id={item} style={{ maxWidth: 400 }} />}
      </InfiniteList>
    </Container>
  )
}

export default AvailableCalls
