/**
 * @file Event Details Footer
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AnimatePresence } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../button'
import {
  cancelEventWaitlist,
  registerEvent,
  registerEventWaitlist,
} from '../../../actions/events'
import AuthOverlay from '../../auth-v2/authOverlay'
import { setAuthSuccessCallback } from '../../../actions/auth'

dayjs.extend(relativeTime)

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EventCTA = ({ event }) => {
  const [showAuth, setShowAuth] = useState(false)
  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.auth.accessToken)

  const handleButtonClick = () => {
    if (!event.userRegistered && !event.isHost) {
      let dispatchAction
      if (event.userOffWaitlist) {
        dispatchAction = registerEvent(event.id)
      } else if (event.userOnWaitlist) {
        if (window.confirm('Are you sure you want to leave the waitlist?')) {
          dispatchAction = cancelEventWaitlist(event.id)
        } else {
          return
        }
      } else if (event.metCapacity) {
        dispatchAction = registerEventWaitlist(event.id)
      } else {
        dispatchAction = registerEvent(event.id)
      }

      if (!accessToken) {
        setShowAuth(true)
        dispatch(
          setAuthSuccessCallback(() => {
            dispatch(dispatchAction)
          })
        )
      } else {
        setShowAuth(false)
        dispatch(dispatchAction)
      }
    } else if (event.link) {
      window.open(event.link, '_blank')
    } else if (event.location) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          event.location
        )}`,
        '_blank'
      )
    }
  }

  const showButton = () => {
    if (
      event.loading ||
      ((event.userRegistered || event.isHost) &&
        dayjs(event.startDate).diff(dayjs(), 'minute') > 10) ||
      dayjs(event.endDate).isBefore(dayjs())
    )
      return false
    return true
  }

  const renderButtonText = () => {
    if (event.userRegistered || event.isHost) return 'Join Event'
    if (event.userOffWaitlist) return 'Reserve a Spot'
    if (event.userOnWaitlist) return 'Leave the Waitlist'
    return event.metCapacity ? 'Join the Waitlist' : 'Reserve a Spot'
  }

  return (
    <Container>
      {showButton() && (
        <Button onClick={handleButtonClick}>{renderButtonText()}</Button>
      )}
      <AnimatePresence>{showAuth && <AuthOverlay />}</AnimatePresence>
    </Container>
  )
}

EventCTA.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  event: PropTypes.object.isRequired,
}

export default EventCTA
