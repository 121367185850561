/**
 * @file Explore events page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Footer from '../footer'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 75px;
  position: relative;

  > h2 {
    padding-bottom: 10px;
  }
`

const InstaLink = styled.a`
  outline: none;
  text-decoration: none;
  color: ${({ theme }) => theme.Accent};
  font-family: inherit;
`

const ExploreEvents = () => {
  return (
    <Container>
      <h2>Thanks for checking us out</h2>
      <h3>Our events are currently invite-only.</h3>
      <h3>
        Follow us on{' '}
        <InstaLink
          href="https://www.instagram.com/solo__club/"
          target="_blank"
          rel="noopener noreferrer"
        >
          instagram
        </InstaLink>{' '}
        for all the latest updates!
      </h3>
      <Footer positionFixed />
    </Container>
  )
}

export default ExploreEvents
