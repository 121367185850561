/**
 * @file Chat
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import AppLayout from '../appLayout'
import ChatRoom from './chatRoom'
import ChatMessageView from './chatMessageView'
import { joinChatRoom, loadChatRooms } from '../../actions/chat'
import SEO from '../seo'
import ChatTypeToggle from './chatTypeToggle'
import InfiniteList from '../InfiniteList'
import { CHAT_ROOM_TYPE } from '../../constants'

const FlexContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: scroll;
  padding: 20px 5px 20px 0px;
`

const ChatRoomsContainer = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  width: 335px;
  min-width: 300px;
  margin-right: 15px;
`

const Chat = ({ roomID }) => {
  const dispatch = useDispatch()
  const [chatTypeFilter, setChatTypeFilter] = useState(CHAT_ROOM_TYPE.DEFAULT)
  const chatRooms = useSelector(state => {
    switch (chatTypeFilter) {
      case CHAT_ROOM_TYPE.DEFAULT:
        return state.chat.defaultRooms
      case CHAT_ROOM_TYPE.EVENT:
        return state.chat.eventRooms
      default:
        return null
    }
  })

  const chatRoomType = useSelector(
    state => state.chat.roomDetails[roomID]?.type
  )
  const socketConnected = useSelector(state => state.chat.socketConnected)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const showChatRooms = !isMobile || !roomID
  const showChatMessageView = !isMobile || roomID
  const showChatsTitle = !isMobile || (isMobile && !roomID)

  useEffect(() => {
    if (socketConnected && roomID) dispatch(joinChatRoom(roomID))
  }, [dispatch, roomID, socketConnected])

  // get the chatType of the roomID, if there is one
  useEffect(() => {
    setChatTypeFilter(chatRoomType || 'default')
  }, [chatRoomType, roomID])

  if (!socketConnected) {
    return null
  } // todo: change null to loading

  return (
    <AppLayout hideHeaderAndPadding={Boolean(isMobile && roomID)}>
      <SEO title="Chat" description="Chat with your solo mates" />
      {showChatsTitle && <h2>Chats</h2>}
      <FlexContainer>
        {showChatRooms && chatRooms && (
          <ChatRoomsContainer>
            <InfiniteList
              key={chatTypeFilter}
              items={chatRooms.ids}
              loadMoreItems={() =>
                dispatch(loadChatRooms({ roomType: chatTypeFilter }))
              }
              canLoadMore={chatRooms.canLoadMore}
              loading={chatRooms.loading}
              stickyHeader={
                <ChatTypeToggle
                  chatType={chatTypeFilter}
                  onChange={chatType => setChatTypeFilter(chatType)}
                />
              }
            >
              {chatRoomID => (
                <ChatRoom id={chatRoomID} isActive={chatRoomID === roomID} />
              )}
            </InfiniteList>
          </ChatRoomsContainer>
        )}
        {showChatMessageView && <ChatMessageView roomID={roomID} />}
      </FlexContainer>
    </AppLayout>
  )
}

Chat.propTypes = {
  roomID: PropTypes.string,
}

Chat.defaultProps = {
  roomID: '',
}

export default Chat
