/**
 * @file The "webapp" part of the website (handles all the client side routing for auth purposes)
 * @author Alwyn Tan
 */

import React from 'react'
import { Router, Redirect } from '@reach/router'
import { useSelector } from 'react-redux'

import Events from '../components/events'
import Chat from '../components/chat'
import EditProfile from '../components/profile/edit'
import Profile from '../components/profile'
import AppHome from '../components/appHome'
import App404 from '../components/app404'
import UpcomingEvents from '../components/events/upcomingEvents'
import PastEvents from '../components/events/pastEvents'
import ExploreEvents from '../components/events/exploreEvents'
import User from '../components/user'
import EventDetails from '../components/events/eventDetails'
import ProtectedRoute from '../components/protectedRoute'
import OnboardingOverlay from '../components/onboarding/onboardingOverlay'
import AuthOverlay from '../components/auth-v2/authOverlay'
import MatchesReview from '../components/events/matchesReview'
import StripePrebuilt from '../components/stripe/stripePrebuilt'
import CreateEvent from '../components/events/CreateEvent'
import Settings from '../components/settings'
import Calls from '../components/Calls'
import LinkedInCallback from '../components/auth-v2/LinkedInCallback'
import AvailableCalls from '../components/Calls/AvailableCalls'
import PastCalls from '../components/Calls/PastCalls'
import Notifications from '../components/Notifications'
import UpcomingCalls from '../components/Calls/UpcomingCalls'
import CallDetails from '../components/Calls/CallDetails'
import Verification from '../components/Verification'
import EmailVerification from '../components/Verification/EmailVerification'
import Pod from '../components/Pod'
import PodInfo from '../components/Pod/PodInfo'

const App = () => {
  const accessTokenLoaded = useSelector(state => state.auth.accessTokenLoaded)
  const hasAccessToken = useSelector(state => !!state.auth.accessToken)
  const isNewUser = useSelector(state => state.auth.user?.newUser)
  const forceVerify = useSelector(
    state =>
      state.auth.user &&
      !state.auth.user?.pendingLogin &&
      !state.auth.userVerified
  )
  const hasOrderToFulfill = useSelector(state => state.stripe.sessionID)

  // wait for loading to complete
  if (!accessTokenLoaded) return null
  if (forceVerify) return <AuthOverlay initial={false} />
  if (hasAccessToken && isNewUser) return <OnboardingOverlay />
  if (hasOrderToFulfill) return <StripePrebuilt />

  return (
    <>
      <Router basepath="/app" primary={false}>
        <AppHome path="/" />

        <Pod path="pod">
          <PodInfo path=":name" />
        </Pod>

        <Events path="events">
          <ExploreEvents path="/" />
          <ProtectedRoute path="past" component={PastEvents} />
          <ProtectedRoute path="upcoming" component={UpcomingEvents} />
          <ProtectedRoute path="matches/:id" component={MatchesReview} />
          <ProtectedRoute path="create" component={CreateEvent} />
          <ProtectedRoute path="update/:id" component={CreateEvent} />
          <EventDetails path=":id" />
        </Events>

        <Calls path="calls">
          <Redirect from="/" to="available" noThrow />
          <ProtectedRoute path="/details/:id" component={CallDetails} />
          <ProtectedRoute path="available" component={AvailableCalls} />
          <ProtectedRoute path="upcoming" component={UpcomingCalls} />
          <ProtectedRoute path="past" component={PastCalls} />
          {/* Support old route by redirecting */}
          <Redirect from="founder" to="/app/pod/founder" />
          <Redirect from="sohoworks" to="/app/pod/sohoworks" />
          <Redirect from="ritzcarltonlalive" to="/app/pod/ritzcarlton-lalive" />
        </Calls>

        <ProtectedRoute path="/notifications" component={Notifications} />

        <ProtectedRoute path="/settings" component={Settings} />

        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/profile/edit" component={EditProfile} />

        <ProtectedRoute path="/chat/*roomID" component={Chat} />

        <User path="/user/:id" />

        <Redirect from="/e/:id" to="/app/events/:id" noThrow />

        {/* Extra routes to allow users to log in with their socials */}
        <ProtectedRoute
          path="/auth/linkedin/callback"
          component={LinkedInCallback}
        />

        <Verification path="verification">
          <EmailVerification path="/email" />
        </Verification>

        <App404 default />
      </Router>
    </>
  )
}

export default App
