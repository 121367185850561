/* eslint-disable react/jsx-props-no-spreading */

/**
 * @file Nav component for past and upcoming
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const FullContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 75px;
  left: 0px;
  display: flex;
  justify-content: center;
  z-index: 1;
  background-color: ${({ theme }) => theme.Primary};
`

const Container = styled.div`
  max-width: 400px;
  width: 100%;
`

const Links = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  padding-bottom: 5px;
`

const StyledLink = styled(({ active, ...props }) => <Link {...props} />)`
  color: ${({ theme }) => theme.Text};
  text-decoration: none;
  margin-right: 25px;
  opacity: ${props => (props.active ? 1 : 0.5)};

  > div {
    display: ${props => (props.active ? 'block' : 'none')};
  }
`

const Line = styled.div`
  width: 75%;
  height: 2px;
  background-color: ${({ theme }) => theme.Text};
  border-radius: 2px;
  margin-top: 2px;
`

const PastAndUpcomingNav = () => {
  return (
    <FullContainer>
      <Container>
        <h2 style={{ paddingBottom: 15 }}>Events</h2>
        <Links>
          <StyledLink
            to="/app/events/past"
            active={window.location.pathname === '/app/events/past'}
          >
            <h3>Past</h3>
            <Line />
          </StyledLink>
          <StyledLink
            to="/app/events/upcoming"
            active={window.location.pathname === '/app/events/upcoming'}
          >
            <h3>Upcoming</h3>
            <Line />
          </StyledLink>
        </Links>
      </Container>
    </FullContainer>
  )
}

export default PastAndUpcomingNav
