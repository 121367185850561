/**
 * @file An overlay component that swipes up for authentication
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import Login from './login'
import Register from './register'
import Verify from './verify'
import Gate from './gate'
import AppLayout from '../appLayout'

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.Secondary};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.Text};
`

const AuthOverlay = () => {
  const userVerified = useSelector(state => state.auth.userVerified)
  const accessToken = useSelector(state => state.auth.accessToken)
  const user = useSelector(state => state.auth.user)
  const authPhoneNumber = useSelector(state => state.auth.authPhoneNumber)

  return (
    <AppLayout hideHeaderAndPadding>
      <Container
        initial={{ top: '100%' }}
        animate={{ top: 0 }}
        exit={{ top: '100%' }}
      >
        {accessToken && !userVerified ? (
          <Verify />
        ) : (
          <AnimatePresence initial={false}>
            {!authPhoneNumber ? (
              <Gate key="gate" />
            ) : (
              <>
                {user?.registered ? (
                  <Login key="login" />
                ) : (
                  <Register key="register" />
                )}
              </>
            )}
          </AnimatePresence>
        )}
      </Container>
    </AppLayout>
  )
}

export default AuthOverlay
