/**
 * @file Onboarding for the user
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import AppLayout from '../appLayout'
import Page0 from './page0'
import Page1 from './page1'
import Button from '../button'
import FormProgressBar from '../FormProgressBar'
import { onboardUser, updateOnboardingUserStore } from '../../actions/user'

const Container = styled(motion.div)`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  max-width: 375px;
  flex-direction: column;
  padding: 60px 30px;
  position: relative;
  display: table;
  min-height: 100%;
  height: 100%;
`

const FlexForm = styled.form`
  min-height: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;

  h3 {
    margin-bottom: 7px;
  }

  input {
    margin-bottom: 20px;
  }

  label {
    margin-bottom: 10px;
  }
`

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`

const TitleText = styled.div`
  text-align: center;
  width: 100%;

  > h3 {
    margin-top: 5px;
  }
`

const BackButton = styled.span`
  font-size: 22px;
  position: absolute;
  left: 30px;
  cursor: pointer;
  transition: 0.25s ease;

  :hover {
    opacity: 0.6;
  }
`

const OnboardingOverlay = () => {
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  })
  const { segments, progress } = useSelector(
    state => state.user.onboardingUserStore
  )
  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.user.loading)
  const onSubmit = data => {
    dispatch(updateOnboardingUserStore({ progress: progress + 1, data }))
    if (progress === segments - 1) dispatch(onboardUser())
  }

  const renderInputs = () => {
    switch (progress) {
      case 0:
        return <Page0 control={control} />
      case 1:
        return <Page1 register={register} />
      default:
        return null
    }
  }

  const handleBackClick = () => {
    dispatch(updateOnboardingUserStore({ progress: progress - 1 }))
  }

  return (
    <AppLayout hideHeaderAndPadding>
      <Container animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        <FlexForm onSubmit={handleSubmit(onSubmit)}>
          <TitleBar>
            {progress > 0 && (
              <BackButton
                className="icon-chevron-left"
                onClick={handleBackClick}
              />
            )}
            <TitleText>
              <h2>Welcome, {user.name}</h2>
              <h3>Let's get you started with Solo</h3>
            </TitleText>
          </TitleBar>
          <div>{renderInputs()}</div>
          <div style={{ flex: 1 }} />
          <div>
            <FormProgressBar segments={segments} progress={progress} />
            <Button
              style={{ marginTop: 10, width: '100%' }}
              disabled={!formState.isValid || loading}
            >
              {progress === segments - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
        </FlexForm>
      </Container>
    </AppLayout>
  )
}

export default OnboardingOverlay
