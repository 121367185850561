/**
 * @file Create Event page 1
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { PureInput } from '../../input'
import Checkbox from '../../Checkbox'

const Page1 = ({ register, watch }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  const isOnlineEvent = watch('online')

  return (
    <>
      <h3>Max Participants</h3>
      <PureInput
        key="maxParticipants"
        name="maxParticipants"
        placeholder="Max Participants"
        type="number"
        ref={register({
          required: true,
          validate: value => !Number.isNaN(value),
        })}
        defaultValue={currentFormData.maxParticipants || ''}
      />
      <h3>
        {isOnlineEvent ? 'Event Link' : 'Location '}
        {!isOnlineEvent && <span style={{ opacity: 0.25 }}>(Optional)</span>}
      </h3>
      {isOnlineEvent ? (
        <PureInput
          key="link"
          name="link"
          placeholder="Event Link"
          ref={register({ required: true })}
          defaultValue={currentFormData.link || ''}
        />
      ) : (
        <PureInput
          key="location"
          name="location"
          placeholder="Location"
          ref={register}
          defaultValue={currentFormData.location || ''}
        />
      )}
      <Checkbox
        name="online"
        ref={register}
        defaultChecked={currentFormData.online}
      >
        Is Online Event
      </Checkbox>
    </>
  )
}

Page1.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
}

export default Page1
