/**
 * @file Sign Up Form
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { PureInput } from '../input'
import Button from '../button'
import {
  loginAccount,
  setAuthError,
  setAuthPhoneNumber,
  setCurrentUser,
  sendVerificationCode,
} from '../../actions/auth'
import ForgotPassword from './forgotPassword'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }
`

const ClickHere = styled.h5`
  cursor: pointer;
  color: ${({ theme }) => theme.Accent};
  font-size: 14px;
`

const AuthError = styled.p`
  color: ${({ theme }) => theme.Accent};
`

const Login = () => {
  const dispatch = useDispatch()

  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const authError = useSelector(state => state.auth.authError)
  const user = useSelector(state => state.auth.user)

  const [name] = useState(user?.name)
  const [showForgotPasswordFlow, setShowForgotPasswordFlow] = useState(false)

  const onSubmit = values => {
    dispatch(
      loginAccount({
        password: values.password,
      })
    )
  }

  const handleForgotPassword = () => {
    setShowForgotPasswordFlow(true)
    dispatch(sendVerificationCode())
  }

  const handleResetUser = () => {
    navigate(`${window.location.origin}${window.location.pathname}`, {
      replace: true,
    })
    setTimeout(() => {
      dispatch(setAuthPhoneNumber(null))
      dispatch(setCurrentUser(null))
      dispatch(setAuthError(false))
    }, 100)
  }

  if (showForgotPasswordFlow) return <ForgotPassword />

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Welcome Back{name && ` ${name}`}!</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>Let's sign you in</h4>
      <PureInput
        placeholder="Password"
        name="password"
        type="password"
        autoComplete="new-password"
        ref={register({
          required: true,
          validate: value => value.length || 'This is required.',
        })}
      />
      {authError && <AuthError>{authError}</AuthError>}
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
      <ClickHere onClick={handleForgotPassword}>
        Forgot Your Password?
      </ClickHere>
      <ClickHere onClick={handleResetUser}>Not You?</ClickHere>
    </Form>
  )
}

export default Login
