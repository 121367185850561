/**
 * @file Create Event page 2
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import ImageInputV2 from '../../ImageInputV2'

const Page3 = ({ control }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  return (
    <>
      <h3>
        Add an Event Photo <span style={{ opacity: 0.25 }}>(Optional)</span>
      </h3>
      <Controller
        as={<ImageInputV2 resizeWidth={800} resizeHeight={400} />}
        name="picture"
        control={control}
        defaultValue={currentFormData?.picture}
      />
    </>
  )
}

Page3.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Page3
