/**
 * @file Image input + preview
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Compressor from 'compressorjs'

import Button from './button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  height: 150px;
  width: 150px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 15px 0;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  > div {
    color: ${({ theme }) => theme.Primary};
    font-size: 50px;
  }
`

const ImageInput = ({ onChange, currentPicture }) => {
  const handleImageUpload = e => {
    if (e.target.files.length > 0) {
      // always compress and resize
      new Compressor(e.target.files[0], {
        width: 500,
        height: 500,
        success(result) {
          onChange(result)
        },
        error(err) {
          console.error(err.message)
        },
      })
    }
  }

  return (
    <Container>
      <ImageContainer>
        {currentPicture ? (
          <img src={currentPicture} alt="profile pic" />
        ) : (
          <div className="icon-camera" />
        )}
      </ImageContainer>
      <Button
        tiny
        style={{
          position: 'relative',
          cursor: 'pointer',
          backgroundColor: 'white',
          color: 'black',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        {currentPicture ? (
          <>
            <span className="icon-edit" style={{ paddingRight: 7 }} />
            Change
          </>
        ) : (
          <>
            <span
              className="icon-upload"
              style={{
                fontSize: 15,
                paddingRight: 7,
              }}
            />
            Upload Photo
          </>
        )}
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      </Button>
    </Container>
  )
}

ImageInput.propTypes = {
  onChange: PropTypes.func,
  currentPicture: PropTypes.string,
}

ImageInput.defaultProps = {
  onChange: () => {},
  currentPicture: '',
}

export default ImageInput
