/* eslint-disable react/jsx-props-no-spreading */

/**
 * @file Nav component for Calls Page
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const FullWidthContainer = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: ${({ theme }) => theme.Primary};
`

const Links = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
`

const StyledLink = styled(({ active, ...props }) => <Link {...props} />)`
  color: ${({ theme }) => theme.Text};
  text-decoration: none;
  margin-right: 25px;
  opacity: ${props => (props.active ? 1 : 0.5)};

  > div {
    display: ${props => (props.active ? 'block' : 'none')};
  }
`

const Line = styled.div`
  width: 75%;
  height: 2px;
  background-color: ${({ theme }) => theme.Text};
  border-radius: 2px;
  margin-top: 2px;
`

const CallsNav = ({ style }) => (
  <FullWidthContainer>
    <Container style={style}>
      <h2 style={{ paddingBottom: 15 }}>Hotlines</h2>
      <Links>
        <StyledLink
          to="/app/calls/available"
          active={window.location.pathname === '/app/calls/available'}
        >
          <h3>Available</h3>
          <Line />
        </StyledLink>
        <StyledLink
          to="/app/calls/upcoming"
          active={window.location.pathname === '/app/calls/upcoming'}
        >
          <h3>Upcoming</h3>
          <Line />
        </StyledLink>
        <StyledLink
          to="/app/calls/past"
          active={window.location.pathname === '/app/calls/past'}
        >
          <h3>Past</h3>
          <Line />
        </StyledLink>
      </Links>
    </Container>
  </FullWidthContainer>
)

CallsNav.propTypes = { style: PropTypes.oneOfType([PropTypes.object]) }
CallsNav.defaultProps = { style: {} }

export default CallsNav
