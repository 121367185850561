/**
 * @file event details screen
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { AnimatePresence } from 'framer-motion'
import toast from 'react-hot-toast'
import {
  cancelEventRegistration,
  getEventDetails,
} from '../../../actions/events'

import EventCardList from './eventCardList'
import EventCTA from './eventCTA'
import EventTag from './eventTag'
import placeholderImage from '../../../images/profilePlaceholder.png'
import EventParticipantList from './eventParticipantList'
import EventLoading from './eventLoading'
import SEO from '../../seo'
import AdditionalInfoModal from '../additionalInfoModal'
import useQueryString from '../../../hooks/useQueryString'
import EventDropdownButton from './EventDropdownButton'
import AddToCalendar from '../../AddToCalendar'

const TitleImage = styled.img`
  width: 100%;
  height: 215px;
  background-color: ${({ theme }) => theme.Secondary};
  object-fit: cover;
  border-radius: 5px;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 5px;
`

const Content = styled.div`
  padding: 20px 20px 100px 20px;
  p {
    opacity: 0.8;
  }
`

const EventInfoItem = styled.div`
  padding-top: 10px;
  display: flex;

  > span {
    font-size: 22px;
    padding-right: 10px;
  }
`
const EventButtonRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`

const EventIconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  cursor: pointer;

  > span {
    font-size: 22px;
    text-align: center;
  }
`

const EventSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid
    ${({ theme }) => (theme.isDarkMode ? '#ffffff' : '#000000')}26;

  h3 {
    padding-bottom: 5px;
  }

  p {
    white-space: pre-wrap;
  }
`

const MarginBottom = styled.div`
  height: 90px;
`

const HostedContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const HostPicture = styled.img`
  object-fit: cover;
  border-radius: 28px;
  width: 56px;
  height: 56px;
`

const LocationLink = styled.a`
  outline: none;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  > h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const EventDetails = ({ id }) => {
  const dispatch = useDispatch()
  const event = useSelector(state => state.events.eventDetails[id])
  const currentUserID = useSelector(state => state.auth.user?.id)
  const queryString = useQueryString()

  const handleCancelClick = () => {
    if (event.userRegistered && window.confirm('Are you sure?')) {
      dispatch(cancelEventRegistration(event.id))
    }
  }

  // ask for event details? on every refresh?
  useEffect(() => {
    dispatch(getEventDetails(id))
  }, [])

  useEffect(() => {
    if (!event) dispatch(getEventDetails(id))
  }, [event, dispatch, id])

  useEffect(() => {
    if (event?.notFound) navigate('/app/404', { replace: true })
  }, [event])

  useEffect(() => {
    if (queryString.success) {
      toast.success('Registered')
      setTimeout(() => {
        navigate(`/app/events/${id}`)
      }, 2000)
    }
  }, [queryString.success])

  // todo: put loading screen instead of null
  if (!event || event?.notFound) return null

  // event.loading
  if (event.loading) {
    return <EventLoading />
  }

  const shouldShowModal = () => {
    return (
      currentUserID &&
      event.userRegistered &&
      event.additionalInfoQuestion &&
      !event.additionalInfo?.[currentUserID]
    )
  }

  return (
    <>
      <AnimatePresence>
        {shouldShowModal() && <AdditionalInfoModal eventID={id} />}
      </AnimatePresence>
      <SEO
        title={event.title}
        description={event.about}
        image={event.picture}
      />
      <Container>
        <TitleImage src={event.picture} />
        {dayjs().isAfter(dayjs(event.endDate)) &&
        !event.userRegistered &&
        !event.isHost ? (
          <Content>
            <h2>{event.title}</h2>
            <h3 style={{ paddingTop: 10 }}>Event is Over</h3>
          </Content>
        ) : (
          <Content>
            {event.location === 'online' && <EventTag tag="online" />}
            <h2>{event.title}</h2>
            {event.location !== 'online' && event.location && (
              <EventInfoItem>
                <span className="icon-map-pin" style={{ fontSize: 22 }} />
                <LocationLink
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    event.location
                  )}`}
                >
                  <h4>{event.location}</h4>
                </LocationLink>
              </EventInfoItem>
            )}
            {event.startDate && (
              <EventInfoItem>
                <span className="icon-calendar" />
                <AddToCalendar
                  event={{
                    description: event.about,
                    location: event.location,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    title: event.title,
                  }}
                  labelText={dayjs(event.startDate).format('D MMMM YYYY')}
                />
              </EventInfoItem>
            )}
            {event.startDate && event.endDate && (
              <EventInfoItem>
                <span className="icon-clock" />
                <h4>
                  {dayjs(event.startDate).format('h:mma')} -{' '}
                  {dayjs(event.endDate).format('h:mma')}
                </h4>
              </EventInfoItem>
            )}
            {event.participants && (
              <EventInfoItem>
                <span className="icon-user" />
                <h4>
                  {event.participants.length} / {event.maxParticipants} seats
                </h4>
              </EventInfoItem>
            )}
            <EventInfoItem>
              <span className="icon-tag" />
              <h4>{event.price ? `$${event.price / 100}` : 'Free'}</h4>
            </EventInfoItem>
            {(event.userRegistered || event.isHost) && (
              <EventButtonRow>
                <EventIconButton
                  onClick={() => {
                    navigate(`/app/chat/${event.chatRoom}`)
                  }}
                >
                  <span className="icon-message-circle" />
                  <h4>Message</h4>
                </EventIconButton>
                {dayjs().isBefore(dayjs(event.startDate)) &&
                  (event.isHost ? (
                    <EventDropdownButton eventID={event.id} />
                  ) : (
                    <EventIconButton onClick={handleCancelClick}>
                      <span className="icon-edit" />
                      <h4>Cancel</h4>
                    </EventIconButton>
                  ))}
              </EventButtonRow>
            )}
            {event.about && (
              <EventSection>
                <h3>About</h3>
                <p>{event.about}</p>
              </EventSection>
            )}
            {(event.userRegistered || event.isHost) && (
              <>
                {event.settings.showGuestList && (
                  <EventSection>
                    <h3>Who's Coming</h3>
                    <EventParticipantList participants={event.participants} />
                  </EventSection>
                )}
                {event.additionalInfoQuestion && event.settings.showQuestion && (
                  <EventSection>
                    <h3>{event.additionalInfoQuestion}</h3>
                    <EventCardList additionalInfo={event.additionalInfo} />
                  </EventSection>
                )}
                {event.settings.showFunFacts && (
                  <EventSection>
                    <h3>Things About Us</h3>
                    <EventCardList
                      participants={event.participants}
                      type="funFact"
                    />
                  </EventSection>
                )}
                {event.settings.showProfessions && (
                  <EventSection>
                    <h3>What We do</h3>
                    <EventCardList
                      participants={event.participants}
                      type="profession"
                    />
                  </EventSection>
                )}
              </>
            )}
            {event.host && (
              <EventSection>
                <HostedContainer>
                  <div>
                    <h3>Hosted by {event.host.name}</h3>
                    <p>Joined {dayjs(event.host.joined).format('MMMM YYYY')}</p>
                  </div>
                  <HostPicture src={event.host.picture || placeholderImage} />
                </HostedContainer>
                <p style={{ paddingTop: 20 }}>{event.host.host_info}</p>
              </EventSection>
            )}
          </Content>
        )}
      </Container>
      <MarginBottom />
      {dayjs(event.endDate).isAfter(dayjs()) && <EventCTA event={event} />}
    </>
  )
}

EventDetails.propTypes = {
  id: PropTypes.string,
}

EventDetails.defaultProps = {
  id: '',
}

export default EventDetails
