/**
 * @file Helpers for the redirect functionality
 * @author Alwyn Tan
 */

import { navigate } from 'gatsby'

/**
 * Attaches a to redirect query param to the route.
 * If a redirect already exists, use that old redirect path instead.
 * @param {Boolean} makeNew make a new redirect to current path if true. NO redirects if false (defaults to false)
 */
export const createRedirectParam = makeNew => {
  const redirectURL = new URL(window.location.href).searchParams.get('redirect')

  if (redirectURL) return `?redirect=${redirectURL}`
  if (makeNew) return `?redirect=${window.location.pathname}`
  return ''
}

/**
 * Execute redirection from the search query param (if exist)
 * If fallbackRedirect exist, will redirect to that address if no redirect query param exists
 * @param {String} fallbackRedirect fallback redirect if there is no redirect param
 */
export const redirectFromParams = fallbackRedirect => {
  const redirectURL = new URL(window.location.href).searchParams.get('redirect')

  if (redirectURL) navigate(redirectURL, { replace: true })
  else if (fallbackRedirect) navigate(fallbackRedirect, { replace: true })
}
