/**
 * @file Chat Item in a list
 * @author Alwyn Tan
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'

import placeholderImage from '../../images/profilePlaceholder.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-left: 5px;
  cursor: pointer;
  background-color: ${props =>
    props.isActive ? props.theme.Secondary : 'transparent'};
  box-shadow: ${({ theme, isActive }) =>
    !theme.isDarkMode && isActive
      ? '0px 2px 8px rgba(0, 0, 0, 0.15)'
      : 'unset'};
  border-radius: 10px;
  width: 100%;
`

const ProfilePic = styled.img`
  margin-left: 10px;
  height: 56px;
  width: 56px;
  min-height: 56px;
  min-width: 56px;
  border-radius: 50%;
  object-fit: cover;
`

const ChatInfo = styled.div`
  color: inherit;
  padding-left: 12px;
  width: 100%;

  * {
    font-weight: ${props =>
      props.hasUnreadMessages ? '800 !important' : '500'};
  }

  h5 {
    opacity: 0.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Dot = styled.span`
  font-size: 50%;
  position: relative;
  bottom: 2.5px;
  padding-left: 5px;
`

const LastMessage = styled.div`
  display: flex;
  flex-direction: 'row';
`

const makeRoomDetailsSelector = () =>
  createSelector(
    state => state.chat.roomDetails,
    (_, id) => id,
    (roomDetails, id) => roomDetails[id]
  )

const ChatRoom = ({ id, isActive }) => {
  const selectRoomDetails = useMemo(makeRoomDetailsSelector, [])
  const roomDetails = useSelector(state => selectRoomDetails(state, id))
  const userID = useSelector(state => state.auth.user.id)

  const handleClick = () => {
    navigate(`/app/chat/${id}`)
  }

  const makeLastMessageString = () => {
    if (roomDetails.lastMessage) {
      const prefix = roomDetails.lastMessage.from.id === userID ? 'You: ' : ''
      return `${prefix}${roomDetails.lastMessage.value}`
    }

    return ''
  }

  return (
    <Container onClick={handleClick} isActive={isActive}>
      <ProfilePic src={roomDetails.picture || placeholderImage} />
      <ChatInfo
        hasUnreadMessages={
          roomDetails.lastMessage &&
          !roomDetails.lastMessage?.seen?.some(uid => uid === userID)
        }
      >
        <h4>{roomDetails.name}</h4>
        {roomDetails.lastMessage && (
          <LastMessage>
            <h5 style={{ maxWidth: 160 }}>{makeLastMessageString()}</h5>
            <h5>
              <Dot>•</Dot> {dayjs(roomDetails.lastMessage.time).format('h:mma')}
            </h5>
          </LastMessage>
        )}
      </ChatInfo>
    </Container>
  )
}

ChatRoom.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
}

ChatRoom.defaultProps = {
  isActive: false,
}

export default ChatRoom
