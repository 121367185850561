/**
 * @file Card for the past callss
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import placeholderImage from '../../images/profilePlaceholder.png'
import { rateMatch } from '../../actions/match'

import Rating from '../Rating'
import PlainCard from './PlainCard'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }

  > div:not(:last-child) {
    margin-bottom: 10px;
  }

  img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    object-fit: cover;
  }
`

const Overlay = styled.div`
  backdrop-filter: blur(6px);
  background-color: ${({ theme }) => `${theme.Secondary}50`};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  text-align: center;
  padding: 20px;
`

// const TippingButtonContainer = styled.div`
//   > button {
//     margin: 10px 10px 0 10px;
//   }
// `

const StyledLink = styled(Link)`
  text-decoration: none;
`

const PastCallCard = ({ id, style }) => {
  const dispatch = useDispatch()
  const matchDetails = useSelector(state => state.match.details[id])

  const renderRatingOverlay = () => (
    <Overlay>
      <h3>How’s your call with {matchDetails.withUser.name}?</h3>
      <Rating
        onRatingChange={rating => dispatch(rateMatch({ id, rating }))}
        rating={matchDetails.rating}
      />
    </Overlay>
  )

  // const renderTippingOverlay = () => (
  //   <Overlay>
  //     <h3>Thank you! Would you like to send them a virtual coffee tip?</h3>
  //     <TippingButtonContainer>
  //       <ButtonV2 size="small">Of Course!</ButtonV2>
  //       <ButtonV2 size="small" type="secondary">
  //         Nope
  //       </ButtonV2>
  //     </TippingButtonContainer>
  //   </Overlay>
  // )

  return (
    <PlainCard style={style}>
      {!matchDetails.rating && renderRatingOverlay()}
      {/* {!matchDetails.tipped && renderTippingOverlay()} */}
      <Container>
        <div>
          <img src={matchDetails.withUser.picture || placeholderImage} alt="" />
          <div style={{ paddingLeft: 15 }}>
            <h3 style={{ paddingBottom: 4 }}>{matchDetails.withUser.name}</h3>
            <StyledLink to={`/app/user/${matchDetails.withUser.id}`}>
              <h4>View Profile</h4>
            </StyledLink>
          </div>
        </div>
        <div>
          <h5>{matchDetails.forEventID.hotline.title}</h5>
          <h5 style={{ opacity: 0.5, paddingLeft: 10 }}>
            {dayjs(matchDetails.forEventID.startDate).format('DD MMM YYYY')}
          </h5>
        </div>
        <div>
          <Rating
            onRatingChange={rating => dispatch(rateMatch({ id, rating }))}
            rating={matchDetails.rating}
          />
          <h5 style={{ opacity: 0.5, paddingLeft: 10 }}>(Tap to Change)</h5>
        </div>
      </Container>
    </PlainCard>
  )
}

PastCallCard.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

PastCallCard.defaultProps = { style: {} }

export default PastCallCard
